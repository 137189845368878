import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { ASvg } from 'ui'
import SvgArrowRightIcon from 'ui/svg/icons/stroke/arrow-right.svg'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { CraftLocationDoctor } from '../../craft-types'
import avatar from '../../helpers/avatar'
import { ADetailsAvatar } from '../atoms/a-details-avatar'

type Props = {
  doctors: Array<CraftLocationDoctor>
}

export const CDetailsDoctors = ({ doctors }: Props) => {
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  const photo = (doctor: CraftLocationDoctor) => {
    let image = ''
    if (doctor.doctorPicture?.length) {
      image = doctor.doctorPicture[0].url
    }
    return avatar('doctor', image, siteInfo?.layout ?? 'icl')
  }

  return (
    <div>
      <div className="font-semibold text-2xl text-color-gray-900 mb-10 text-center lg:text-left">
        {doctors.length} {doctors.length === 1 ? t('doctor') : t('doctors')}
      </div>
      <div className="flex flex-wrap">
        {doctors.map((doctor, n) => {
          return (
            <Link
              key={n}
              href={`/doctors/${doctor.slug}/about`}
              prefetch={false}
              className="mt-[1.5rem] md:mt-[-1px] relative w-full md:w-1/2 border border-gray-200 flex flex-col items-center justify-center p-6 group"
            >
              <ADetailsAvatar
                avatar={photo(doctor)}
                specialist={doctor.doctorSpecialist}
                small={true}
              />
              <h3 className="font-body text-2xl text-gray-900 font-semibold mt-6">
                {doctor.title}
              </h3>
              <span
                className={classNames(
                  'link text-sm font-body font-bold uppercase inline-flex items-center mt-4',
                  {
                    'text-primary-teal':
                      siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                    'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                  }
                )}
              >
                <span>{t('more-details')}</span>
                <ASvg
                  svg={SvgArrowRightIcon}
                  className={classNames(
                    'arrow stroke-current text-primary-teal ml-3 w-6 h-6 group-hover:translate-x-1 transition-transform duration-200',
                    {
                      'text-primary-teal':
                        siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                      'text-pink-500':
                        siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                    }
                  )}
                />
              </span>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
