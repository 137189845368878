import classNames from 'classnames'
import { ChangeEvent } from 'react'
import css from 'styled-jsx/css'

type Props = {
  value: string
  label: string
  name: string
  isChecked: boolean
  onChange?: (value: string) => void
  styles?: JSX.Element
}

export const ASortByRadio = ({
  value,
  label,
  name,
  isChecked,
  onChange,
  styles = defaultStyles,
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange instanceof Function) {
      onChange(event.target.id)
    }
  }

  return (
    <div>
      <input
        id={value}
        type="radio"
        name={name}
        checked={isChecked}
        className={classNames('a-sort-by-radio', {
          'a-sort-by-radio--not-selected': !isChecked,
          'a-sort-by-radio--selected': isChecked,
        })}
        onChange={handleChange}
      />
      <label
        htmlFor={value}
        className={classNames('cursor-pointer a-sort-by-radio ml-1', {
          'a-sort-by-radio--not-selected': !isChecked,
          'a-sort-by-radio--selected': isChecked,
        })}
      >
        {label}
      </label>
      <style jsx>{styles}</style>
    </div>
  )
}

const defaultStyles = css`
  .a-sort-by-radio:hover {
    text-decoration-line: underline;
    text-decoration-color: #10a8ba;
    text-decoration-thickness: 4px;
    text-underline-offset: 4px;
    color: #57606c;
  }

  .a-sort-by-radio--selected {
    text-decoration-line: underline;
    text-decoration-color: #10a8ba;
    text-decoration-thickness: 4px;
    text-underline-offset: 4px;
    color: #57606c;
    accent-color: #909495;
  }

  .a-sort-by-radio--not-selected {
    color: #909495;
  }
`
