import css from 'styled-jsx/css'

type Props = {
  code?: string
}

export const AInlineVideo = ({ code }: Props) => {
  if (!code) {
    return <></>
  }

  return (
    <>
      <div
        className="a-inline-video"
        dangerouslySetInnerHTML={{ __html: code }}
      />
      <style jsx>{styles}</style>
    </>
  )
}

const styles = css`
  .a-inline-video {
    height: 0;
    margin-top: 2.5rem;
    padding-top: 56.25%;
    position: relative;
    width: 100%;
  }

  .a-inline-video >>> iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`
