import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { ASvg } from 'ui'
import SvgArrowDownIcon from 'ui/svg/icons/stroke/arrow-down.svg'
import { useTranslation } from 'next-i18next'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import useSettings from '../../hooks/use-settings'

type Props = {
  hours: Array<string>
}

const defaultProps = {
  hours: [],
}

const MOfficeHours = ({ hours }: Props) => {
  const { t } = useTranslation('common')
  const settings = useSettings()
  const siteInfo = useSiteInfo()

  const [showMore, setShowMore] = useState<boolean>(false)
  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  const today = useMemo(() => {
    const day = new Date().getDay()
    return day === 0 ? 6 : day - 1
  }, [])

  return (
    <div
      className={classNames({
        'mt-1 font-base font-semibold text-gray-800 leading-7': !siteInfo.isUS,
      })}
    >
      <dl
        className={classNames('flex flex-wrap', {
          'text-sm font-light': siteInfo.isUS,
        })}
      >
        <dt className="w-2/5">{t('today')}</dt>
        <dd className="w-3/5">{hours[today] || '-'}</dd>
      </dl>
      {showMore && (
        <div>
          {Array.from(Array(7)).map((i, n) => {
            return (
              <dl
                key={n}
                className={classNames('flex flex-wrap', {
                  'text-sm font-light': siteInfo.isUS,
                })}
              >
                <dt className="w-2/5">{t(days[n])}</dt>
                <dd className="w-3/5">{hours[n] || '-'}</dd>
              </dl>
            )
          })}
        </div>
      )}
      <button
        className={classNames('show-more text-sm', {
          'uppercase font-bold flex items-center mt-4': !siteInfo.isUS,
          'text-primary-teal':
            siteInfo?.layout === CONST.SITE_LAYOUT.ICL && !siteInfo.isUS,
          'text-pink-500':
            siteInfo?.layout === CONST.SITE_LAYOUT.EVO && !siteInfo.isUS,
        })}
        title={showMore ? t('show-less') ?? '' : t('show-more') ?? ''}
        onClick={() => setShowMore(!showMore)}
      >
        <span className={classNames({ underline: siteInfo.isUS })}>
          {showMore ? t('show-less') : t('show-more')}
        </span>
        {!siteInfo.isUS && (
          <ASvg
            className={classNames(
              'stroke-current transition-transform transform duration-200 ease-out w-6 h-6',
              {
                'rotate-180': showMore,
                'mr-3': settings.rtl,
                'ml-3': !settings.rtl,
              }
            )}
            svg={SvgArrowDownIcon}
          />
        )}
      </button>
    </div>
  )
}

MOfficeHours.defaultProps = defaultProps

export { MOfficeHours }
