import { ReactNode, forwardRef, ForwardedRef } from 'react'

type Props = {
  active: boolean
  title?: string
  handle: string
  children: ReactNode
}

export const ATab = forwardRef(
  ({ active, children }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className="overflow-hidden" ref={ref}>
        {active ? children : <></>}
      </div>
    )
  }
)
