import classNames from 'classnames'
import { ASvg } from 'ui'
import { useTranslation } from 'next-i18next'
import SvgCloseIcon from 'ui/svg/icons/stroke/close.svg'
import SvgArrowLeftIcon from 'ui/svg/icons/stroke/arrow-left.svg'
import { useRouter } from 'next/router'
import {
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { AppContext } from '../../context/app'
import useSettings from '../../hooks/use-settings'

export type MModalRef = {
  scrollToTop: () => void
  scrollToTabs: () => void
}

type Props = {
  mobileTitle?: string | null
  top?: ReactNode
  content: ReactNode
  placement?: 'center' | 'right'
}

export const MModal = forwardRef(
  ({ top, content, mobileTitle, placement = 'center' }: Props, ref) => {
    const { t } = useTranslation('common')
    const router = useRouter()
    const settings = useSettings()
    const siteInfo = useSiteInfo()
    const isRightPlacement = Boolean(placement === 'right')

    const componentRef = useRef<HTMLDivElement>(null)

    const appContext = useContext(AppContext)

    const handleClose = () => {
      const query = {
        q: appContext?.state?.query?.q ?? '',
        type: appContext?.state?.query?.type || settings.types.default,
        radius: appContext?.state?.query?.radius || settings.radius.default,
        sort: appContext?.state?.query?.sort || settings.sort.default,
      }

      const queryParams = new URLSearchParams(query).toString()
      router.push(`/?${queryParams}`, undefined, { shallow: true })
      if (componentRef.current) {
        enableBodyScroll(componentRef.current)
      }
    }

    const handleClickBack = () => {
      router.back()
    }

    useEffect(() => {
      if (componentRef.current) {
        disableBodyScroll(componentRef.current)
      }
    }, [])

    useImperativeHandle(
      ref,
      () => {
        return {
          scrollToTop() {
            if (componentRef.current) {
              componentRef.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              })
            }
          },
          scrollToTabs() {
            if (componentRef.current) {
              const media = window.matchMedia('(max-width: 1023px)')
              const tabs = document.querySelector('.c-details__tabs')

              if (media.matches && tabs) {
                componentRef.current.scrollTo({
                  top:
                    tabs.getBoundingClientRect().top +
                    document.documentElement.scrollTop -
                    95,
                  behavior: 'smooth',
                })
              }
            }
          },
        }
      },
      []
    )

    return (
      <div
        ref={componentRef}
        className="bg-black bg-opacity-50 fixed inset-0 z-50 w-full h-screen overflow-y-auto"
        onClick={handleClose}
      >
        <div
          className={classNames('bg-white relative', {
            'mt-[15vh] overflow-auto lg:min-h-full lg:overflow-visible lg:mt-0 lg:h-auto lg:max-w-[48vw] ml-auto mr-0':
              isRightPlacement,
            'mx-auto container min-h-screen': !isRightPlacement,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={classNames(
              'z-60 left-1/2 -translate-x-1/2 flex items-center justify-between px-4 w-full lg:hidden bg-white',
              {
                'top-0 h-[60px] absolute': isRightPlacement,
                'top-0 container h-[72px] fixed': !isRightPlacement,
              }
            )}
          >
            <button
              className={classNames(
                'w-12 h-12 flex items-center justify-center',
                {
                  invisible: isRightPlacement,
                }
              )}
              title={t('back') ?? ''}
              type="button"
              onClick={handleClickBack}
            >
              <ASvg
                className={classNames('stroke-current w-12 h-12', {
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                })}
                svg={SvgArrowLeftIcon}
              />
            </button>
            <div
              className={classNames(
                'font-body text-base text-center font-semibold',
                {
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                }
              )}
            >
              {mobileTitle}
            </div>
            <button
              className={classNames('flex items-center justify-center', {
                'w-12 h-12': !isRightPlacement,
                'w-8 h-8': isRightPlacement,
              })}
              title={t('close') ?? ''}
              onClick={handleClose}
            >
              <ASvg
                className={classNames('fill-current', {
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                  'w-10 h-10': !isRightPlacement,
                  'w-8 h-8': isRightPlacement,
                })}
                svg={SvgCloseIcon}
              />
            </button>
          </div>
          <div
            className={classNames('relative hidden lg:block', {
              'border-b border-gray-200 bg-gray-100 h-64': !isRightPlacement,
            })}
          >
            {top}
            <button
              className={classNames(
                'w-[88px] h-[88px] bg-gray-900 rounded-full flex items-center justify-center absolute z-50',
                {
                  '-left-[44px] top-[30px]': isRightPlacement,
                  '-right-[44px] -bottom-[44px]': !isRightPlacement,
                }
              )}
              title={t('close') ?? ''}
              onClick={handleClose}
            >
              <ASvg
                className={classNames('stroke-current w-10 h-10', {
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                })}
                svg={SvgCloseIcon}
              />
            </button>
          </div>
          <div className="min-h-full inline-block w-full">{content}</div>
        </div>
      </div>
    )
  }
)
