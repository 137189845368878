import { GetServerSidePropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { CraftGlobals } from 'tsconfig/craft-types'
import { CSeomatic } from 'ui'
import getSettings from 'shared/helpers/get-settings'
import { useTranslation } from 'next-i18next'
import { useAtom } from 'jotai'
import { getSiteInfo, useSiteInfo } from 'shared'
import getGlobals from '../graphql/get-globals'
import { CDoctorModal } from '../components/organisms/c-doctor-modal'
import { CLandingSearch } from '../components/organisms/c-landing-search'
import { CLocationModal } from '../components/organisms/c-location-modal'
import { CSearch } from '../components/organisms/c-search'
import { AppContext, AppContextType, AppState } from '../context/app'
import getInitialContext from '../helpers/get-initial-context'
import useSettings from '../hooks/use-settings'
import DefaultLayout from '../layouts/default'
import { setCacheHeaders } from '../helpers/cache-config'
import {
  CRequestCallbackModal,
  requsetCallbackModalAtom,
} from '../components/organisms/c-request-callback-modal'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const siteInfo = getSiteInfo(context.locale ?? '')
  const settings = getSettings(
    siteInfo?.layout ?? 'icl',
    siteInfo?.iso ?? 'en-US'
  )

  const global = !/^.+-evo$/.test(context.locale ?? '')
    ? await getGlobals(siteInfo?.handle)
    : {}

  const initialAppContext = await getInitialContext(
    context.req.url ?? '',
    settings,
    siteInfo
  )

  if (!initialAppContext) {
    return {
      notFound: true,
    }
  }

  setCacheHeaders(context.res)

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? '', ['common'])),
      global,
      initialAppContext,
    },
  }
}

type Props = {
  global: CraftGlobals
  initialAppContext: AppState
}

export default function Index({ global }: Props) {
  const router = useRouter()
  const settings = useSettings()
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()
  const displayQuiz = Boolean(router.asPath === '/' && siteInfo.isUS)

  const appContext = useContext(AppContext) as AppContextType

  const isDoctorPath = /^\/doctors\/(?<slug>[^/]+)\/(?<tab>[^?^/]+)/.test(
    router.asPath
  )
  const isLocationPath = /^\/locations\/(?<slug>[^/]+)\/(?<tab>[^?^/]+)/.test(
    router.asPath
  )

  const [isOpenRequestCallbackModal, _setIsOpenResquestModal] = useAtom(
    requsetCallbackModalAtom
  )

  useEffect(() => {
    if (router.query.requestcallbackwithoutprovider !== undefined) {
      _setIsOpenResquestModal(true)
    }

    if (router.query.q && !isDoctorPath && !isLocationPath) {
      appContext.updateQuery({
        q: router.query.q ? (router.query.q as string) : '',
        type: ['doctors', 'locations'].includes(
          (router.query.type as string) ?? ''
        )
          ? (router.query.type as string)
          : settings.types.default,
        sort: ['experience', 'distance'].includes(
          (router.query.sort as string) ?? ''
        )
          ? (router.query.sort as string)
          : settings.sort.default,
        radius: /^[0-9]+$/.test((router.query.radius as string) ?? '')
          ? (router.query.radius as string)
          : '',
      })
    }
  }, [router.query])

  const shouldRenderRequestCallbackModal =
    siteInfo.isUS && isOpenRequestCallbackModal

  return (
    <DefaultLayout global={global}>
      {/*
          Temporal disable
          {displayQuiz && (
            <Provider value={doctorsIclClient}>
              <CQuiz />
            </Provider>
          )}
       */}
      <CSeomatic
        data={
          isDoctorPath
            ? appContext.state.doctor?.seomatic ?? null
            : isLocationPath
            ? appContext.state.location?.seomatic ?? null
            : null
        }
        overrideTitle={
          isDoctorPath
            ? appContext.state.doctor.title ?? t('find-a-doctor')
            : isLocationPath
            ? appContext.state.location?.title ?? t('find-a-doctor')
            : t('find-a-doctor')
        }
      />
      {!router.query.q &&
        /^\/\?{0,1}/.test(router.asPath) &&
        !isDoctorPath &&
        !isLocationPath && (
          <>
            {siteInfo.isUS ? (
              <div>
                <CSearch isLanding />
              </div>
            ) : (
              <div
                className={
                  siteInfo.isJP
                    ? 'h-[max(100vh,990px)] md:h-[max(100vh,940px)]'
                    : 'h-[max(100vh,860px)]'
                }
              >
                <CLandingSearch />
              </div>
            )}
          </>
        )}
      {(router.query.q || isDoctorPath || isLocationPath) && (
        <>
          <CSearch />
          {isDoctorPath && <CDoctorModal />}
          {isLocationPath && <CLocationModal />}
        </>
      )}
      {shouldRenderRequestCallbackModal && <CRequestCallbackModal />}
    </DefaultLayout>
  )
}
