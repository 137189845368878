import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { AppContext } from '../../context/app'
import { MModal, MModalRef } from '../molecules/m-modal'
import avatar from '../../helpers/avatar'
import { ATab } from '../atoms/a-tab'
import { MShowMore } from '../molecules/m-show-more'
import { AInlineVideo } from '../atoms/a-inline-video'
import { CraftLocation } from '../../craft-types'
import { CDetails } from './c-details'
import { CMap } from './c-map'
import { CContactForm } from './c-contact-form'
import { CGallery } from './c-gallery'
import { CInformation } from './c-information'
import { CDetailsLocations } from './c-details-locations'

export const CDoctorModal = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  const modalRef = useRef<MModalRef>(null)

  const [contactFormStep, setContactFormStep] = useState<number>(1)

  const appContext = useContext(AppContext)

  const urlData = useMemo(() => {
    const matches = router.asPath.match(
      /^\/(?<type>doctors|locations)\/(?<slug>[^/]+)\/(?<tab>[^?^/]+)/
    )

    if (
      matches?.groups?.type &&
      matches?.groups?.slug &&
      matches?.groups?.tab
    ) {
      return {
        type: matches.groups.type,
        slug: decodeURI(matches.groups.slug),
        tab: matches.groups.tab,
      }
    }

    return {
      type: null,
      slug: null,
      tab: null,
    }
  }, [router.asPath])

  const location = useMemo(() => {
    if (
      appContext?.state?.doctors?.length &&
      appContext.state.doctor &&
      appContext.state.doctor.doctorLocations?.length
    ) {
      const doctors = appContext.state.doctors || []
      const locations = appContext.state.doctor.doctorLocations || []
      const doctor = doctors.find(
        (v) => v.id === Number(appContext.state.doctor.id)
      )

      if (doctor) {
        const index =
          locations.findIndex((v) => v.locationAddress === doctor.address) || 0
        return appContext.state.doctor.doctorLocations[index]
      }
    }

    return {
      locationLatitude: 0,
      locationLongitude: 0,
      locationHours: [],
      locationLanguages: [],
      locationWebsite: { url: '' },
    }
  }, [appContext?.state?.doctor])

  const computedHeading = useMemo(() => {
    if (urlData.tab === CONST.URL_DATA_TAB.CONTACT) {
      if (contactFormStep === 1) {
        return siteInfo.isUS
          ? appContext?.state.doctor.title
          : `${t(CONST.URL_DATA_TAB.CONTACT)} ${
              appContext?.state?.doctor?.title
            }`
      } else {
        return t('message-sent')
      }
    } else {
      return appContext?.state?.doctor?.title
    }
  }, [urlData.tab, appContext?.state?.doctor])

  const officeHours = useMemo(() => {
    return location.locationHours?.map((v) => (v.text || '').trim()) ?? []
  }, [location])

  const website = useMemo(() => {
    return location.locationWebsite?.url ?? ''
  }, [location])

  const certificates = useMemo(() => {
    return (appContext?.state?.doctor?.doctorCertificates || []).map(
      (v) => v.text
    )
  }, [appContext?.state?.doctor])

  const education = useMemo(() => {
    return (appContext?.state?.doctor?.doctorEducation || []).map((v) => v.text)
  }, [appContext?.state?.doctor])

  const languages = useMemo(() => {
    return (location.locationLanguages || []).map((v) => v.text)
  }, [appContext?.state?.doctor])

  const photo = useMemo(() => {
    let image = ''
    if (appContext?.state?.doctor?.doctorPicture?.length) {
      image = (appContext.state.doctor.doctorPicture[0] || {}).url
    }
    return avatar('doctor', image, siteInfo?.layout ?? 'icl')
  }, [appContext?.state.doctor])

  const distance = useMemo(() => {
    const doctor = appContext?.state.doctors.find(
      (v) => v.slug === appContext?.state.doctor.slug
    )

    if (doctor) {
      return doctor.distance
    }

    return 0
  }, [appContext?.state?.doctor])

  const handleScrollToTop = () => {
    if (modalRef.current) {
      modalRef.current.scrollToTop()
    }
  }

  const handleScrollToTabs = () => {
    if (modalRef.current) {
      modalRef.current.scrollToTabs()
    }
  }

  useEffect(() => {
    if (
      ![
        CONST.URL_DATA_TAB.ABOUT,
        CONST.URL_DATA_TAB.LOCATIONS,
        CONST.URL_DATA_TAB.INFORMATION,
        CONST.URL_DATA_TAB.CONTACT,
      ].includes(urlData.tab ?? '')
    ) {
      router.replace(
        `/${urlData.type}/${urlData.slug}/${CONST.URL_DATA_TAB.ABOUT}`,
        undefined,
        { shallow: true }
      )
    }

    ;(async () => {
      if (urlData.slug && appContext?.state.doctor.slug !== urlData.slug) {
        appContext?.updateDoctor(urlData.slug)
      }
    })()
  }, [])

  if (siteInfo.isUS) {
    return (
      <MModal
        placement="right"
        ref={modalRef}
        content={
          <>
            {appContext?.state.doctor.slug === urlData.slug && (
              <CDetails
                doctor={appContext.state.doctor}
                name={computedHeading ?? ''}
                photo={photo}
                distance={distance}
                specialist={appContext.state.doctor.doctorSpecialist}
                location={location as unknown as CraftLocation}
                hours={officeHours}
                onScrollToTop={handleScrollToTop}
                onScrollToTabs={handleScrollToTabs}
                biography={appContext.state.doctor.doctorBiography}
                certificates={certificates}
                education={education}
                languages={languages}
                displayContact={Boolean(urlData.tab === 'contact')}
                website={website}
                contactForm={
                  <CContactForm
                    doctor={appContext.state.doctor}
                    location={location as CraftLocation}
                    name={appContext.state.doctor.title}
                    step={contactFormStep}
                    setStep={setContactFormStep}
                  />
                }
              />
            )}
          </>
        }
      />
    )
  }

  return (
    <MModal
      ref={modalRef}
      mobileTitle={t(urlData.tab ?? '')}
      top={
        <>
          {appContext?.state?.doctor?.slug === urlData.slug && (
            <CMap
              markers={[
                {
                  lat: location.locationLatitude ?? 0,
                  lng: location.locationLongitude ?? 0,
                  selected: true,
                  clickable: false,
                },
              ]}
            />
          )}
        </>
      }
      content={
        <>
          {appContext?.state?.doctor?.slug === urlData.slug && (
            <CDetails
              doctor={appContext.state.doctor}
              name={computedHeading ?? ''}
              photo={photo}
              distance={distance}
              specialist={appContext.state.doctor.doctorSpecialist}
              location={location as unknown as CraftLocation}
              hours={officeHours}
              onScrollToTop={handleScrollToTop}
              onScrollToTabs={handleScrollToTabs}
            >
              <ATab
                active={urlData.tab === CONST.URL_DATA_TAB.ABOUT}
                handle={CONST.URL_DATA_TAB.ABOUT}
                title={t(CONST.URL_DATA_TAB.ABOUT) ?? ''}
              >
                {appContext.state.doctor.doctorBiography && (
                  <>
                    <h2 className="font-body text-2xl text-gray-900 font-semibold">
                      {t(CONST.URL_DATA_TAB.ABOUT)}{' '}
                      {appContext.state.doctor.title}
                    </h2>
                    <MShowMore
                      className="mt-4"
                      content={appContext.state.doctor.doctorBiography}
                    />
                  </>
                )}
                {!!appContext.state.doctor.doctorVideo?.length && (
                  <AInlineVideo
                    code={
                      appContext.state.doctor.doctorVideo[0].embeddedAsset.code
                    }
                  />
                )}
                {!!appContext.state.doctor.doctorGallery?.length && (
                  <>
                    <h2 className="font-body text-2xl text-gray-900 font-semibold mt-10">
                      {t('photos-of')} {appContext.state.doctor.title}
                    </h2>
                    <CGallery
                      className="mt-4"
                      images={appContext.state.doctor.doctorGallery}
                    />
                  </>
                )}
              </ATab>
              <ATab
                active={urlData.tab === CONST.URL_DATA_TAB.INFORMATION}
                handle={CONST.URL_DATA_TAB.INFORMATION}
                title={t(CONST.URL_DATA_TAB.INFORMATION) ?? ''}
              >
                <CInformation
                  name={appContext.state.doctor.title ?? ''}
                  rows={[
                    [
                      {
                        label: t('languges-spoken'),
                        type: 'list',
                        items: languages,
                      },
                    ],
                    [
                      {
                        label: t('certification-and-association'),
                        type: 'list',
                        items: certificates,
                      },
                    ],
                    [
                      {
                        label: t('education'),
                        type: 'list',
                        items: education,
                      },
                    ],
                  ]}
                  social={appContext.state.doctor.doctorSocial ?? []}
                  doctor={appContext.state.doctor}
                />
              </ATab>
              {!!appContext.state.doctor.doctorLocations?.length && (
                <ATab
                  active={urlData.tab === CONST.URL_DATA_TAB.LOCATIONS}
                  handle={CONST.URL_DATA_TAB.LOCATIONS}
                  title={t(CONST.URL_DATA_TAB.LOCATIONS) ?? ''}
                >
                  <CDetailsLocations
                    locations={appContext.state.doctor.doctorLocations ?? []}
                  />
                </ATab>
              )}
              <ATab
                active={urlData.tab === CONST.URL_DATA_TAB.CONTACT}
                handle={CONST.URL_DATA_TAB.CONTACT}
              >
                <CContactForm
                  doctor={appContext.state.doctor}
                  location={location as CraftLocation}
                  name={appContext.state.doctor.title}
                  step={contactFormStep}
                  setStep={setContactFormStep}
                />
              </ATab>
            </CDetails>
          )}
        </>
      }
    />
  )
}
