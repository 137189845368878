import { useTranslation } from 'next-i18next'

export const ANoResults = () => {
  const { t } = useTranslation('common')

  return (
    <div className="px-8 py-12">
      <h1 className="text-4xl font-light">{t('no-results')}</h1>
      <h2 className="mt-2 text-2xl text-gray-800 font-light">
        {t('sorry-we-couldnt-find-any-results-based-on-your-search')}
      </h2>
    </div>
  )
}
