import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import {
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ASvg } from 'ui'
import SvgLoadingIcon from 'ui/svg/icons/fill/loading.svg'
import SvgSearchIcon from 'ui/svg/icons/stroke/search.svg'
import SvgLocationIcon from 'ui/svg/icons/fill/location-alt.svg'
import SvgLocationIconUS from 'ui/svg/icons/fill/location-alt-us.svg'
import SvgRefreshIcon from 'ui/svg/icons/fill/refresh.svg'
import css from 'styled-jsx/css'
import { useRouter } from 'next/router'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import useSettings from '../../hooks/use-settings'
import useMapbox from '../../hooks/use-mapbox'
import { AppContext } from '../../context/app'

type MapboxResult = {
  place_name: string
}

type Props = {
  onSubmit: (value: string) => void
}

export const MSearchInput = ({ onSubmit }: Props) => {
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()
  const settings = useSettings()
  const mapbox = useMapbox()
  const router = useRouter()

  const [error, setError] = useState<boolean>(false)
  const [inputFocus, setInputFocus] = useState<boolean>(false)
  const [inputHasValue, setInputHasValue] = useState<boolean>(false)
  const [geolocationState, setGeolocationState] = useState<string>('')
  const [autocompleteResults, setAutocompleteResults] = useState<Array<string>>(
    []
  )
  const [autocompleteHover, setAutocompleteHover] = useState<number>(0)
  const [autocompleteShow, setAutocompleteShow] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement | null>(null)
  const geolocationTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const autocompleteTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const appContext = useContext(AppContext)

  const handleGeolocationSearch = () => {
    setGeolocationState('loading')

    if (geolocationTimeout.current) {
      clearTimeout(geolocationTimeout.current)
    }

    navigator.geolocation.getCurrentPosition(
      async (data) => {
        const { latitude, longitude } = data.coords
        const location = await mapbox.reverseGeocode(latitude, longitude)

        if (location.features.length > 0) {
          const query = {
            q: location.features[0].place_name ?? '',
            type: appContext?.state?.query?.type ?? '',
          }
          const queryParams = new URLSearchParams(query).toString()
          router.push(`/?${queryParams}`)

          setGeolocationState('default')
        } else {
          setGeolocationState('error')
          geolocationTimeout.current = setTimeout(() => {
            setGeolocationState('default')
          }, 3e3)
        }
      },
      () => {
        setGeolocationState('error')
        geolocationTimeout.current = setTimeout(() => {
          setGeolocationState('default')
        }, 3000)
      }
    )
  }

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault()
  }

  const handleSubmit = () => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current)
    }

    if (inputRef.current) {
      inputRef.current.blur()
    }

    setAutocompleteResults([])

    const piiRegex = /(\S+@\S+)/g

    if (inputRef.current && piiRegex.test(inputRef.current.value)) {
      console.log(
        'Refusing to search for something that looks like personal information.'
      )
      return
    }

    if (inputRef.current && onSubmit instanceof Function) {
      onSubmit(inputRef.current.value)
    }
  }

  const handleAutocompleteSubmit = (event: MouseEvent, item: string) => {
    event.preventDefault()
    if (inputRef.current) {
      inputRef.current.value = item
    }
    handleSubmit()
  }

  const autocompleteUpdate = () => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current)
    }

    if (
      inputFocus &&
      inputRef.current &&
      settings.autocomplete &&
      typeof settings.autocomplete === 'number' &&
      inputRef.current.value.length >= settings.autocomplete
    ) {
      autocompleteTimeout.current = setTimeout(async () => {
        try {
          const res = await mapbox.forwardGeocode(inputRef.current?.value ?? '')
          setAutocompleteResults(
            res.features.map((v: MapboxResult) => {
              let name = v.place_name
              if (siteInfo?.iso === 'en-US') {
                name = name.split(', ').slice(0, -1).join(', ')
              }
              return name
            })
          )
          setAutocompleteHover(0)
        } catch (error) {
          console.log(error)
        }
      }, 500)
    } else {
      setAutocompleteResults([])
      setAutocompleteHover(0)
    }
  }

  const handleInputFocus = () => {
    setInputFocus(true)
    setAutocompleteShow(true)
    if (autocompleteResults.length === 0 && inputHasValue) {
      autocompleteUpdate()
    }
  }

  const handleInputBlur = () => {
    setTimeout(() => {
      setInputFocus(false)
      setAutocompleteHover(0)
      setAutocompleteShow(false)
    }, 200)
  }

  const calcAutocompleteHover = (index: number) => {
    if (index > autocompleteResults.length) {
      setAutocompleteHover(1)
    } else if (index < 1) {
      setAutocompleteHover(autocompleteResults.length)
    } else {
      setAutocompleteHover(index)
    }
  }

  const handleInputKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      calcAutocompleteHover(autocompleteHover - 1)
    } else if (event.key === 'ArrowDown') {
      event.preventDefault()
      if (!autocompleteShow) {
        setAutocompleteHover(1)
        setAutocompleteShow(true)
      } else {
        calcAutocompleteHover(autocompleteHover + 1)
      }
    } else if (event.key === 'Enter') {
      if (autocompleteHover > 0) {
        event.preventDefault()
        if (inputRef.current && autocompleteResults[autocompleteHover - 1]) {
          inputRef.current.value = autocompleteResults[autocompleteHover - 1]
        }
        setAutocompleteShow(false)
      }
      handleSubmit()
    } else {
      if (inputRef.current && inputRef.current.value !== '') {
        setInputHasValue(true)
      } else {
        setInputHasValue(false)
      }
    }
  }

  const geolocationButtonText = () => {
    switch (geolocationState) {
      case 'default':
        return t('search-by-my-current-location')
      case 'loading':
        return t('searching')
      case 'error':
        return t('location-not-found')
    }
  }

  const geolocationButtonIcon = () => {
    switch (geolocationState) {
      case 'default':
        return siteInfo.isUS ? SvgLocationIconUS : SvgLocationIcon
      case 'loading':
        return SvgLoadingIcon
      case 'error':
        return SvgRefreshIcon
    }
  }

  const updateValue = () => {
    if (inputRef.current && appContext?.state?.query?.q) {
      inputRef.current.value = appContext.state.query.q ?? ''
      setInputHasValue(true)
    }
    setError(
      !!appContext?.state?.query?.q &&
        !appContext?.state?.query?.lat &&
        !appContext?.state?.query?.lng
    )
  }

  useEffect(() => {
    setGeolocationState(navigator.geolocation && 'default')
  }, [])

  useEffect(() => {
    updateValue()
  }, [appContext?.state?.query?.q])

  return (
    <form onSubmit={handleFormSubmit}>
      <div
        className={classNames('border border-gray-800 flex w-full relative', {
          'bg-primary-300': error,
          'bg-neutral-white': !error,
          'rounded-full px-2 max-w-3xl relative z-10': siteInfo.isUS,
          'rounded-md p-2': !siteInfo.isUS,
        })}
      >
        {siteInfo.isUS && (
          <label htmlFor="search-bar" className="hidden">
            {t('enter-a-city-state-or-region')}
          </label>
        )}
        <input
          ref={inputRef}
          id="search-bar"
          type="text"
          className={classNames(
            'flex-grow outline-none text-base font-semibold bg-transparent text-gray-900 px-2',
            {
              'boder-none text-gray-900': !siteInfo.isUS,
              'text-neutral-black placeholder-neutral-black text-lg':
                siteInfo.isUS,
            }
          )}
          aria-label={t('enter-a-city-state-or-region') ?? ''}
          placeholder={
            siteInfo.isUS ? t('enter-a-city-state-or-region') ?? '' : undefined
          }
          onChange={autocompleteUpdate}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyUp={handleInputKeyUp}
          autoComplete="off"
        />
        {!siteInfo.isUS && (
          <label
            className={classNames(
              'text-base font-semibold absolute pointer-events-none transition-all duration-200 ease-out top-1/2 px-2',
              {
                'text-gray-800 -translate-y-1/2': !inputFocus && !inputHasValue,
                'text-primary-teal -translate-y-10 bg-neutral-white':
                  inputFocus || inputHasValue,
                'custom-label-rtl': settings.rtl,
              }
            )}
          >
            {t('enter-a-city-state-or-region')}
          </label>
        )}
        <button
          className={classNames(
            'flex-shrink-0 h-12 w-12 rounded flex items-center justify-center transition-colors duration-200 ease-out',
            {
              'bg-primary-teal':
                siteInfo?.layout === CONST.SITE_LAYOUT.ICL && !siteInfo.isUS,
            }
          )}
          type="button"
          onClick={handleSubmit}
          title={t('search') ?? ''}
        >
          <ASvg
            className={classNames({
              'text-neutral-white w-8 h-8': !siteInfo.isUS,
              'text-neutral-black w-6 h-6': siteInfo.isUS,
              'fill-current': geolocationState === 'loading',
              'stroke-current': geolocationState !== 'loading',
            })}
            svg={
              geolocationState === 'loading' ? SvgLoadingIcon : SvgSearchIcon
            }
          />
        </button>
        <ul
          className={classNames(
            'm-search-input__autocomplete absolute bg-neutral-white border border-gray-800 pb-2',
            {
              'opacity-0 pointer-events-none':
                !autocompleteShow || !autocompleteResults.length,
            }
          )}
        >
          {autocompleteResults.map((item: string, index: number) => {
            return (
              <li key={index}>
                <a
                  className={classNames(
                    'block my-1 px-4 py-2 text-base transition-colors duration-100',
                    {
                      'hover:bg-gray-100 hover:text-primary-teal':
                        autocompleteHover === 0,
                      'bg-gray-100 text-primary-teal':
                        index === autocompleteHover - 1,
                      'text-neutral-black': siteInfo.isUS,
                    }
                  )}
                  data-autocomplete="true"
                  href="#"
                  onMouseMove={() => {
                    setAutocompleteHover(0)
                  }}
                  onClick={(e: MouseEvent) => handleAutocompleteSubmit(e, item)}
                >
                  {item}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      {error && (
        <div
          className={classNames('mt-2 ml-4 text-sm', {
            'text-white': siteInfo.isUS,
            'text-primary-teal': !siteInfo.isUS,
          })}
        >
          {t('location-not-found')}
        </div>
      )}
      {!!geolocationState && (
        <button
          className="mt-4 flex items-center text-primary-teal hover:text-primary-dark-teal transition-colors duration-200 ease-out"
          type="button"
          onClick={handleGeolocationSearch}
        >
          <ASvg
            className="location-icon fill-current mx-2 w-6 h-6"
            svg={geolocationButtonIcon()}
          />
          <div
            className={classNames('text-sm', {
              'font-bold tracking-wider uppercase': !siteInfo.isUS,
              'text-white': siteInfo.isUS,
            })}
          >
            {geolocationButtonText()}
          </div>
        </button>
      )}
      <style jsx>{styles}</style>
    </form>
  )
}

const styles = css`
  .m-search-input__autocomplete {
    border-radius: 0 0 0.375rem 0.375rem;
    border-top: none;
    left: -1px;
    right: -1px;
    top: 95%;
    transition: opacity 200ms ease;
    z-index: 50;
  }
`
