import classNames from 'classnames'
import { ASvg } from 'ui'
import SvgArrowLeft from 'ui/svg/icons/stroke/arrow-left.svg'
import SvgArrowRight from 'ui/svg/icons/stroke/arrow-right.svg'
import { useSiteInfo } from 'shared'

type Props = {
  pages: number
  page: number
  onChange?: (page: number) => void
}

export const MListPagination = ({ pages, page, onChange }: Props) => {
  const siteInfo = useSiteInfo()

  const handlePageChange = (index: number) => {
    if (onChange instanceof Function) {
      onChange(index)
    }
  }

  if (pages <= 1) {
    return <></>
  }

  return (
    <div
      className={classNames('bg-white flex p-4', {
        'justify-center': !siteInfo.isUS,
        'justify-start': siteInfo.isUS,
      })}
    >
      <button
        className={classNames(
          'flex items-center justify-center text-base font-semibold border border-gray-800 mx-1 rounded w-10 h-10',
          {
            'opacity-20': page === 0,
            'text-primary-teal': page !== 0,
          }
        )}
        disabled={page === 0}
        onClick={() => handlePageChange(page - 1)}
      >
        <ASvg className="w-8 h-8 stroke-current" svg={SvgArrowLeft} />
      </button>
      {Array.from(Array(pages)).map((e, i) => {
        return (
          <button
            key={i}
            className={classNames(
              'flex items-center justify-center text-base font-semibold border mx-1 rounded w-10 h-10 transition-colors duration-100',
              {
                'border-primary-teal bg-primary-teal text-neutral-white':
                  i === page,
                'border-gray-800 text-gray-800': i !== page,
              }
            )}
            onClick={() => handlePageChange(i)}
          >
            {i + 1}
          </button>
        )
      })}
      <button
        className={classNames(
          'flex items-center justify-center text-base font-semibold border border-gray-800 mx-1 rounded w-10 h-10',
          {
            'opacity-20': page === pages - 1,
            'text-primary-teal': page < pages - 1,
          }
        )}
        disabled={page === pages - 1}
        onClick={() => handlePageChange(page + 1)}
      >
        <ASvg className="w-8 h-8 stroke-current" svg={SvgArrowRight} />
      </button>
    </div>
  )
}
