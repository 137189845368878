import { DefaultTFuncReturn } from 'i18next'
import Image from 'next/image'

interface MHeadingProps {
  title?: DefaultTFuncReturn
  subtitle?: DefaultTFuncReturn
  children?: JSX.Element
  rightContent?: JSX.Element
  bgIcon?: JSX.Element
}

export const MHeading = ({
  children,
  rightContent,
  bgIcon,
  title,
  subtitle,
}: MHeadingProps) => {
  return (
    <div className="bg-teal relative text-white w-full px-5 md:flex xl:px-10 py-5 lg:py-12">
      <div className="flex-1 relative z-40">
        {title && <h1 className="text-3xl mb-3">{title}</h1>}
        {subtitle && <p className="text-lg mb-5 lg:mb-7">{subtitle}</p>}
        {children}
      </div>
      {rightContent && (
        <div className="hidden md:block my-auto px-30">{rightContent}</div>
      )}
      <Image
        className="absolute bottom-0 right-0 block h-full w-auto z-0"
        src="/img/m-heading-find.png"
        alt="Background shape"
        width={290}
        height={272}
      />
    </div>
  )
}
