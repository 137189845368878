import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { ASvg } from 'ui'
import Image from 'next/image'
import SvgSpecialistIcon from 'ui/svg/icons/fill/specialist.svg'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'

type Props = {
  avatar?: string
  specialist?: boolean
  small?: boolean
}

const defaultProps = {
  avatar: null,
  specialist: false,
  small: false,
}

const ADetailsAvatar = ({ avatar, specialist, small }: Props) => {
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  return (
    <div className="relative">
      <div
        className={classNames(
          'rounded-full overflow-hidden bg-gray-100 relative',
          {
            'w-[138px] h-[138px] sm:w-[220px] sm:h-[220px]': !small,
            'w-[112px] h-[112px]': small,
            'shadow-lg': !siteInfo.isUS,
          }
        )}
      >
        {avatar && (
          <Image
            className="w-full h-full object-cover"
            src={avatar}
            alt={t('avatar') ?? ''}
            fill
            style={{ objectFit: 'cover' }}
          />
        )}
      </div>
      {specialist && (
        <div
          className={classNames(
            'border-4 bg-white rounded-full flex items-center justify-center absolute',
            {
              'w-[36px] h-[36px] sm:w-[56px] sm:h-[56px] bottom-[7px] right-[6px] sm:bottom-[8px] sm:right-[8px]':
                !small,
              'w-[36px] h-[36px] bottom-0 right-0': small,
              'border-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
              'border-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
            }
          )}
        >
          <ASvg
            className={classNames(
              'w-8 h-8 sm:w-12 sm:h-12 fill-current text-primary-teal',
              {
                'text-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
              }
            )}
            svg={SvgSpecialistIcon}
          />
        </div>
      )}
    </div>
  )
}

ADetailsAvatar.defaultProps = defaultProps

export { ADetailsAvatar }
