import classNames from 'classnames'
import { MouseEvent, useMemo } from 'react'
import { ASvg } from 'ui'
import { useTranslation } from 'next-i18next'
import css from 'styled-jsx/css'
import SvgFacebookIcon from 'ui/svg/icons/social/Facebook.svg'
import SvgInstagramIcon from 'ui/svg/icons/social/Instagram.svg'
import SvgLinkedinIcon from 'ui/svg/icons/social/Linkedin.svg'
import SvgTwitterIcon from 'ui/svg/icons/social/Twitter.svg'
import SvgYelpIcon from 'ui/svg/icons/social/Yelp.svg'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { CraftDoctor } from '../../craft-types'

type SocialItem = {
  icon: string
  link: string
  text: string
}

type Props = {
  name: string
  rows: Array<
    Array<{
      label: string
      type: string
      items: Array<string>
    }>
  >
  social?: Array<SocialItem>
  doctor?: CraftDoctor
}

type SocialIconProps = {
  iconName: string
}

const SocialIcon = ({ iconName }: SocialIconProps) => {
  let icon = null

  switch (iconName) {
    case 'facebook':
      icon = SvgFacebookIcon
      break
    case 'instagram':
      icon = SvgInstagramIcon
      break
    case 'linkedin':
      icon = SvgLinkedinIcon
      break
    case 'twitter':
      icon = SvgTwitterIcon
      break
    case 'yelp':
      icon = SvgYelpIcon
      break
  }

  if (icon) {
    return (
      <ASvg
        className="fill-current icon w-12 h-12 flex-shrink-0 mr-2"
        svg={icon}
      />
    )
  }

  return <></>
}

export const CInformation = ({ name, rows, social, doctor }: Props) => {
  const siteInfo = useSiteInfo()
  const { t } = useTranslation('common')

  const filteredRows = useMemo(() => {
    return rows
      .map((row) => {
        return row
          .map((col) => {
            return { ...col, items: col.items.filter((item) => item) }
          })
          .filter((col) => col.items.length)
      })
      .filter((row) => row.length)
  }, [rows])

  const filteredSocial = useMemo(() => {
    return (social || []).filter((row) => {
      return row.icon && row.link && row.text
    })
  }, [social])

  const pushSegment = (event: string, data: any, item: SocialItem) => {
    const externalEvent = new CustomEvent('showExternalModal', {
      detail: item.link,
    })
    if (!window.analytics || !siteInfo.isUS) {
      window.dispatchEvent(externalEvent)
    } else {
      window.analytics.track(event, data, () => {
        window.dispatchEvent(externalEvent)
      })
    }
  }

  const handleSocialClick = (event: MouseEvent, item: SocialItem) => {
    event.preventDefault()

    if (doctor?.id) {
      pushSegment(
        'Doctor Website Visited',
        {
          doctor_name: name,
          specialist_doctor: doctor.doctorSpecialist,
          doctor_clinic:
            doctor.doctorLocations && doctor.doctorLocations.length
              ? doctor.doctorLocations[0].title
              : '',
          doctor_address:
            doctor.doctorLocations && doctor.doctorLocations.length
              ? doctor.doctorLocations[0].locationAddress
              : '',
          review_type: doctor.doctorReviewType,
          review_available: !!doctor.doctorReviewId,
          clicked_url: item.link,
        },
        item
      )
    } else {
      const event = new CustomEvent('showExternalModal', {
        detail: item.link,
      })
      window.dispatchEvent(event)
    }
  }

  if (siteInfo.isUS) {
    return (
      <div
        className={classNames('font-light', {
          'c-information--evo': siteInfo?.layout === 'evo',
        })}
      >
        {filteredRows.map((row, i) => {
          return (
            <div
              key={i}
              className="c-information__row flex flex-wrap relative mb-10 last:mb-0"
            >
              {row.map((col, n) => {
                return (
                  <div key={n} className="c-information__column relative">
                    <h3 className="uppercase font-semibold text-primary-dark-teal mb-4">
                      {col.label}
                    </h3>
                    {col.type === 'list' && (
                      <ul>
                        {col.items.map((item, idx) => {
                          return (
                            <li
                              key={idx}
                              className={classNames('text-sm mb-2', {
                                'font-light text-2xl leading-10':
                                  siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                              })}
                            >
                              {item}
                            </li>
                          )
                        })}
                      </ul>
                    )}
                    {col.type === 'join' && (
                      <div
                        className={classNames(
                          'text-gray-800 font-light leading-10',
                          {
                            'font-light text-2xl leading-10':
                              siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                          }
                        )}
                      >
                        {col.items.join(', ')}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
        {!!filteredSocial.length && (
          <div>
            <h3 className="leading-10 text-2xl py-8">
              <span className="text-gray-900 font-semibold">
                {name} {t('references')}
              </span>
              {filteredSocial.map((item, n) => {
                return (
                  <a
                    key={n}
                    target="_blank"
                    href={item.link}
                    rel="noreferrer"
                    className={classNames(
                      'cursor-pointer flex mt-4 items-center text-2xl text-light text-gray-800 transition-colors duration-100 ease-out',
                      {
                        'hover:text-primary-teal':
                          siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                        'hover:text-pink-500':
                          siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                      }
                    )}
                    onClick={(e) => handleSocialClick(e, item)}
                  >
                    {item.icon && <SocialIcon iconName={item.icon} />}
                    <span>{item.text}</span>
                  </a>
                )
              })}
            </h3>
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames('font-body -mt-8', {
        'c-information--evo': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
      })}
    >
      {filteredRows.map((row, i) => {
        return (
          <div
            key={i}
            className="c-information__row py-8 flex flex-wrap relative"
          >
            {row.map((col, n) => {
              return (
                <div
                  key={n}
                  className="c-information__column text-2xl relative'"
                >
                  <h3 className="text-gray-900 font-semibold leading-10">
                    {col.label}
                  </h3>
                  {col.type === 'list' && (
                    <ul>
                      {col.items.map((item, idx) => {
                        return (
                          <li
                            key={idx}
                            className={classNames(
                              'text-gray-800 font-light leading-10',
                              {
                                'font-light text-2xl leading-10':
                                  siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                              }
                            )}
                          >
                            {item}
                          </li>
                        )
                      })}
                    </ul>
                  )}
                  {col.type === 'join' && (
                    <div
                      className={classNames(
                        'text-gray-800 font-light leading-10',
                        {
                          'font-light text-2xl leading-10':
                            siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                        }
                      )}
                    >
                      {col.items.join(', ')}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )
      })}
      {!!filteredSocial.length && (
        <div>
          <h3 className="leading-10 text-2xl py-8">
            <span className="text-gray-900 font-semibold">
              {name} {t('references')}
            </span>
            {filteredSocial.map((item, n) => {
              return (
                <a
                  key={n}
                  target="_blank"
                  href={item.link}
                  rel="noreferrer"
                  className={classNames(
                    'cursor-pointer flex mt-4 items-center text-2xl text-light text-gray-800 transition-colors duration-100 ease-out',
                    {
                      'hover:text-primary-teal':
                        siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                      'hover:text-pink-500':
                        siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                    }
                  )}
                  onClick={(e) => handleSocialClick(e, item)}
                >
                  {item.icon && <SocialIcon iconName={item.icon} />}
                  <span>{item.text}</span>
                </a>
              )
            })}
          </h3>
        </div>
      )}
      <style jsx>{styles}</style>
    </div>
  )
}

const styles = css`
  .c-information__row::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 49px;
    height: 6px;
    background-color: #10a8ba;
  }

  .c-information--evo .c-information__row::after {
    background-color: #f86e83;
  }

  .c-information__column {
    width: 100%;
  }

  .c-information__column:first-child {
    padding-bottom: 2rem;
  }

  .c-information__column:last-child {
    padding-top: 2rem;
  }

  .c-information__column:only-child::after {
    display: none;
  }

  .c-information__column:first-child::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 49px;
    height: 6px;
    background-color: #10a8ba;
  }

  .c-information--evo .c-information__column:first-child::after {
    background-color: #f86e83;
  }

  .c-information__column:only-child {
    padding: 0;
  }

  @media (min-width: 768px) {
    .c-information__column {
      width: auto;
      flex: 1 1 0;
    }

    .c-information__column:first-child {
      padding-bottom: 0;
    }

    .c-information__column:last-child {
      padding-top: 0;
    }

    .c-information__column::after {
      display: none;
    }
  }
`
