import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { ASvg } from 'ui'
import { useTranslation } from 'next-i18next'
import css from 'styled-jsx/css'
import SvgArrowDownIcon from 'ui/svg/icons/stroke/arrow-down.svg'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import useSettings from '../../hooks/use-settings'

type Props = {
  className?: string
  content?: string
}

export const MShowMore = ({ className, content }: Props) => {
  const siteInfo = useSiteInfo()
  const settings = useSettings()
  const { t } = useTranslation('common')

  const [showMore, setShowMore] = useState<boolean>(false)

  useEffect(() => {
    if (content && content.length <= 300) {
      setShowMore(true)
    }
  }, [])

  return (
    <div className={className}>
      <div
        className={classNames(
          'text-2xl text-gray-700 font-light leading-10 m-show-more__formatting',
          {
            'text-2xl leading-10': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
            'm-show-more__content': !showMore,
          }
        )}
        dangerouslySetInnerHTML={{ __html: content ?? '' }}
      />
      {content && content.length > 300 && (
        <button
          className={classNames(
            'm-show-more text-sm uppercase font-bold flex items-center mt-4',
            {
              'text-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
              'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
            }
          )}
          title={showMore ? t('show-less') ?? '' : t('show-more') ?? ''}
          onClick={() => setShowMore(!showMore)}
        >
          <span>{showMore ? t('show-less') : t('show-more')}</span>
          <ASvg
            className={classNames(
              'stroke-current transition-transform duration-200 ease-out w-6 h-6',
              {
                'rotate-180': showMore,
                'mr-3': settings.rtl,
                'ml-3': !settings.rtl,
              }
            )}
            svg={SvgArrowDownIcon}
          />
        </button>
      )}
      <style jsx>{styles}</style>
    </div>
  )
}

const styles = css`
  .m-show-more__content {
    max-height: 200px;
    overflow: hidden;
    position: relative;
  }

  .m-show-more__content::after {
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    position: absolute;
    right: 0;
  }

  .m-show-more__formatting >>> p {
    margin-bottom: 12px;
  }

  .m-show-more__formatting >>> b,
  .m-show-more__formatting >>> strong {
    font-weight: 600;
  }

  .m-show-more__formatting >>> i {
    font-style: italic;
  }

  .m-show-more__formatting >>> ul {
    list-style-type: disc;
    margin-left: 24px;
  }

  .m-show-more__formatting >>> ol {
    list-style-type: decimal;
    margin-left: 24px;
  }
`
