import classNames from 'classnames'
import { ChangeEvent, TextareaHTMLAttributes } from 'react'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  onChangeHandler?: (value: string) => void
  version?: 'rounded'
}

export const AFormTextarea = ({
  name,
  placeholder,
  value,
  onChangeHandler,
  version,
}: Props) => {
  const siteInfo = useSiteInfo()

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler && onChangeHandler(event.target.value)
  }

  return (
    <div
      className={classNames(
        'a-form-textarea border border-gray-500 w-full mx-3 mt-6 overflow-hidden',
        {
          'bg-gray-100 rounded-lg': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
          'bg-white': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
          'rounded-3xl': version === 'rounded',
        }
      )}
    >
      <textarea
        aria-label={placeholder}
        className={classNames(
          'p-4 bg-transparent outline-none w-full resize-none',
          {
            'placeholder:text-gray-800': siteInfo.isUS,
          }
        )}
        name={name}
        placeholder={placeholder}
        rows={3}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
