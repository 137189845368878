/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames'
import { MouseEvent } from 'react'
import css from 'styled-jsx/css'
import useSettings from 'doctors/hooks/use-settings'
import SvgCheckTrueIcon from 'ui/svg/icons/fill/check-true.svg'
import SvgCheckFalseIcon from 'ui/svg/icons/fill/check-false.svg'
import SvgSpecialistIcon from 'ui/svg/icons/fill/specialist.svg'

type Props = {
  callback?: Function
  disabled: boolean
  selected: boolean
  item: any
  size: 'small' | 'large'
}

export const MContactFormModalItem = ({
  callback,
  disabled,
  selected,
  item,
  size,
}: Props) => {
  const settings = useSettings()

  const SvgCheckIcon = selected ? SvgCheckTrueIcon : SvgCheckFalseIcon

  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    callback && callback(item)
  }

  return (
    <>
      <a
        className={classNames(
          'border-t flex font-body font-base outline-none p-4 transition-colors duration-200 ease-out',
          {
            'bg-primary-bright-teal': !!callback && selected,
            'bg-gray-300': disabled,
          }
        )}
        href="#"
        onClick={onClick}
      >
        <div
          className={classNames(
            'bg-gray-200 rounded-full flex-shrink-0 overflow-hidden',
            'avatar-' + size
          )}
        >
          <img
            className="w-full h-full object-cover"
            alt={item.title}
            src={item.avatar}
          />
        </div>
        <div className="flex-grow mx-4">
          <div
            className={classNames('font-semibold text-gray-900', {
              'text-xl': size === 'large',
            })}
          >
            {item.title}
          </div>
          <div className="font-semibold text-gray-800">{item.subtitle}</div>
          {size === 'small' && settings.search === 'geocoding' && (
            <div className="font-semibold text-gray-800">
              {item.distance} {settings.unit.label}
            </div>
          )}
          {size === 'large' && (
            <div
              className="mt-1 text-sm text-gray-700"
              dangerouslySetInnerHTML={{
                __html: item?.address?.replace(/\n/, '<br>') ?? '',
              }}
            />
          )}
        </div>
        <div className="flex flex-col flex-shrink-0 items-end text-gray-800">
          <div>
            {size === 'large' && settings.search === 'geocoding' && (
              <span className="font-semibold">
                {item.distance} {settings.unit.label}
              </span>
            )}
            {size === 'large' && (
              <SvgCheckIcon className="check-icon inline text-primary-teal fill-current ml-2" />
            )}
          </div>
          {item.specialist && (
            <SvgSpecialistIcon className="specialist-icon text-primary-teal fill-current mt-4" />
          )}
        </div>
      </a>
      <style jsx>{styles}</style>
    </>
  )
}

const styles = css`
  .avatar-small {
    height: 48px;
    width: 48px;
  }

  .avatar-large {
    height: 72px;
    width: 72px;
  }

  .check-icon {
    height: 20px;
    width: 20px;
  }

  .specialist-icon {
    height: 16px;
    width: 35px;
  }
`
