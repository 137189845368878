import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'

type Image = {
  thumb: string
  image: string
}

type Props = {
  className?: string
  images?: Array<Image>
}

export const CGallery = ({ className, images }: Props) => {
  const { t } = useTranslation('common')
  const [activeImage, setActiveImage] = useState<number>(0)
  const siteInfo = useSiteInfo()

  if (images?.length === 0) {
    return <></>
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <div>
      <div className="mb-4">
        {!!images?.length && (
          <img
            className="mx-auto max-w-full"
            alt={t('gallery-image') ?? ''}
            src={images[activeImage].image}
          />
        )}
      </div>
      <div className="flex flex-wrap -m-2">
        {images?.map((item: Image, n: number) => {
          return (
            <button
              key={n}
              className="w-[calc(25%-1rem)] sm:w-[calc(12.5%-1rem)] m-2"
              title={t('gallery-thumbnail') ?? ''}
              onClick={() => setActiveImage(n)}
            >
              <div className="w-full pt-[100%] h-0 relative">
                <div
                  className={classNames(
                    'absolute w-full h-full top-0 left-0 border-4 z-10 transition-opacity duration-100 ease-out',
                    {
                      'opacity-100': activeImage === n,
                      'opacity-0': activeImage !== n,
                      'border-primary-teal':
                        siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                      'border-pink-500':
                        siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                    }
                  )}
                />
                <img
                  className="absolute w-full h-full object-cover top-0 left-0"
                  alt={t('gallery-thumbnail') ?? ''}
                  src={item.thumb}
                />
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}
