import { useTranslation } from 'next-i18next'
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import TagManager from 'react-gtm-module'
import { useAtom } from 'jotai'
import { useSiteInfo } from 'shared'
import useSettings from '../../hooks/use-settings'
import { MDropdown, MDropdownOption } from '../molecules/m-dropdown'
import { CraftRegion } from '../../craft-types'
import { AppContext, AppContextType } from '../../context/app'
import { MSearchInput } from '../molecules/m-search-input'
import { ASortByRadio } from '../atoms/a-sort-by-radio'
import { MTabs } from '../molecules/m-tabs'
import { ATab } from '../atoms/a-tab'
import { Doctor } from '../../context/reducers/doctors'
import { MListItem } from '../molecules/m-list-litem'
import { Location } from '../../context/reducers/locations'
import { ANoResults } from '../atoms/a-no-results'
import { MListPagination } from '../molecules/m-list-pagination'
import { CLocationDetail } from './c-location-detal'
import {
  requestCallbackModalDoctorSelectedAtom,
  requsetCallbackModalAtom,
} from './c-request-callback-modal'

const isRecentlyRequestedDoctor = (
  appContext: AppContextType | undefined,
  slug: string
) => {
  return appContext?.state?.recentlyRequestedDoctors.includes(slug)
}

export type CListRef = {
  setActiveDoctor: (id: number | null) => void
  setActiveLocation: (id: number | null) => void
}

type Props = {
  loading: boolean
  locationDetail: Location | null
  showLocationDetail: boolean
  onSelected?: (id: number | null, type: string) => void
  onCloseLocationDetail?: () => void
}

export const CList = forwardRef(
  (
    {
      loading,
      locationDetail,
      showLocationDetail,
      onSelected,
      onCloseLocationDetail,
    }: Props,
    ref
  ) => {
    const settings = useSettings()
    const router = useRouter()
    const siteInfo = useSiteInfo()

    const { t } = useTranslation('common')

    const [sortBy, setSortBy] = useState<string>('')
    const [pagination, setPagination] = useState({
      doctors: {
        page: 0,
        pages: 0,
      },
      locations: {
        page: 0,
        pages: 0,
      },
    })

    type ActiveType = {
      doctors: number | null
      locations: number | null
    }

    const [active, setActive] = useState<ActiveType>({
      doctors: null,
      locations: null,
    })

    const doctorsTabsRef = useRef<HTMLDivElement>(null)
    const locationsTabsRef = useRef<HTMLDivElement>(null)

    const appContext = useContext(AppContext)
    const locations = appContext?.state?.locations

    const regionsOptions: Array<MDropdownOption> = useMemo(() => {
      return (appContext?.state?.regions ?? []).map((region: CraftRegion) => {
        return {
          option: region.title ?? '',
          value: region.slug ?? '',
          selected: appContext?.state.query?.q === region.slug,
        }
      })
    }, [appContext?.state?.query?.q, appContext?.state?.regions])

    const radiusOptions: Array<MDropdownOption> = useMemo(() => {
      return (appContext?.state?.radius?.options ?? []).map((option) => ({
        option: `${option.label} ${settings.unit.label}`,
        value: option.label,
        selected: appContext?.state.query.radius
          ? appContext?.state.query.radius === option.label
          : appContext?.state.radius.default === option.label,
      }))
    }, [appContext?.state?.query?.q, appContext?.state?.radius])

    const typesOptions = useMemo(() => {
      return settings.types.options.map((option) => option.value)
    }, [settings.types.options])

    const paginatedDoctors = useMemo(() => {
      const page = pagination.doctors.page || 0
      return appContext?.state?.doctors?.slice(page * 20, (page + 1) * 20)
    }, [appContext?.state?.doctors, pagination.doctors])

    const paginatedLocations = useMemo(() => {
      const page = pagination.locations.page || 0
      return appContext?.state?.locations?.slice(page * 20, (page + 1) * 20)
    }, [appContext?.state?.locations, pagination.locations])

    const updatePagination = () => {
      const newPagination = {
        doctors: {
          page: 0,
          pages: Math.ceil((appContext?.state?.doctors?.length ?? 0) / 20),
        },
        locations: {
          page: 0,
          pages: Math.ceil((appContext?.state?.locations?.length ?? 0) / 20),
        },
      }

      setPagination(newPagination)
    }

    const handleTabChange = (handle: string) => {
      handleItemActive(null, 'clear')

      const query = {
        ...router.query,
        type: handle,
      }

      const queryParams = new URLSearchParams(query).toString()
      router.push(`/?${queryParams}`, undefined, { shallow: true })

      scrollToTop()
    }

    const handleRegionChange = (option: MDropdownOption) => {
      const query = {
        ...router.query,
        q: option.value,
      }

      const queryParams = new URLSearchParams(query).toString()
      router.push(`/?${queryParams}`, undefined, { shallow: true })

      if (appContext?.state?.query?.type === 'doctors') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'doctorSearch',
            event_category: 'Doctor Search',
            event_action: 'List component on search results page',
            event_label: option.value,
          },
        })
      } else if (appContext?.state?.query?.type === 'locations') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'locationSearch',
            event_category: 'Location Search',
            event_action: 'List component on search results page',
            event_label: option.value,
          },
        })
      }
    }

    const handleSortChange = (value: string) => {
      const query = {
        ...router.query,
        sort: value,
      }

      const queryParams = new URLSearchParams(query).toString()
      router.push(`/?${queryParams}`, undefined, { shallow: true })
      setSortBy(value)
    }

    const handleSubmitSearch = (value: string) => {
      if (appContext?.state?.query?.type === 'doctors') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'doctorSearch',
            event_category: 'Doctor Search',
            event_action: 'List component on search results page',
            event_label: value,
          },
        })
      } else if (appContext?.state?.query?.type === 'locations') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'locationSearch',
            event_category: 'Location Search',
            event_action: 'List component on search results page',
            event_label: value,
          },
        })
      }

      const query = {
        q: value,
        type: (router.query.type as string) ?? '',
      }

      const queryParams = new URLSearchParams(query).toString()
      router.push(`/?${queryParams}`)
    }

    const handleRadiusChange = (option: MDropdownOption) => {
      const query = {
        ...router.query,
        radius: option.value,
      }

      const queryParams = new URLSearchParams(query).toString()
      router.push(`/?${queryParams}`)
    }

    const handleItemActive = (id: number | null, type: string) => {
      if (type === 'doctors') {
        setActive({
          ...active,
          doctors: id,
        })
      } else if (type === 'locations') {
        setActive({
          ...active,
          locations: id,
        })
      } else if (type === 'clear') {
        setActive({
          doctors: null,
          locations: null,
        })
      }

      if (onSelected instanceof Function) {
        onSelected(id, type)
      }
    }

    const handleDoctorsPaginationChange = (page: number) => {
      const newPagination = {
        ...pagination,
        doctors: {
          pages: pagination.doctors.pages,
          page,
        },
      }
      setPagination(newPagination)
      scrollToTop()
    }

    const handleLocationsPaginationChange = (page: number) => {
      const newPagination = {
        ...pagination,
        locations: {
          pages: pagination.locations.pages,
          page,
        },
      }
      setPagination(newPagination)
      scrollToTop()
    }

    const handleCloseLocationDetail = () => {
      if (onCloseLocationDetail instanceof Function) {
        onCloseLocationDetail()
      }
    }

    const scrollIntoView = () => {
      type ActiveKey = keyof typeof active

      const type = appContext?.state?.query?.type
      const id = active[type as ActiveKey]

      if (id) {
        let index: number = 0
        if (type === 'doctors') {
          index = appContext?.state?.doctors?.findIndex((v) => v.id === id) ?? 0
        } else if (type === 'locations') {
          index =
            appContext?.state?.locations?.findIndex((v) => v.id === id) ?? 0
          setPagination({
            ...pagination,
            locations: {
              pages: pagination.locations.pages,
              page: Math.floor(index / 20),
            },
          })
        }

        let rootNode: HTMLElement | undefined | null = null
        let itemNode: Element | undefined | null = null

        if (type === 'doctors') {
          rootNode = doctorsTabsRef.current?.parentElement
          itemNode = doctorsTabsRef.current?.children[index % 20]
        } else if (type === 'locations') {
          rootNode = locationsTabsRef.current?.parentElement
          itemNode = locationsTabsRef.current?.children[index % 20]
        }

        if (rootNode && itemNode) {
          const rootRect = rootNode.getBoundingClientRect()
          const itemRect = itemNode.getBoundingClientRect()

          if (
            itemRect.top < rootRect.top ||
            itemRect.top + itemRect.height > rootRect.top + rootRect.height
          ) {
            rootNode.scrollTo({
              top: itemRect.top - rootRect.top + rootNode.scrollTop,
              behavior: 'smooth',
            })
          }
        }
      }
    }

    const scrollToTop = () => {
      const mq = window.innerWidth <= 640 ? 'sm' : 'md'

      if (siteInfo.isUS) {
        setTimeout(() => {
          window.scrollTo({
            top: 250,
            behavior: 'smooth',
          })
        })
      }

      if (mq === 'sm') {
        window.scrollTo({
          top: 250,
          behavior: 'smooth',
        })
      } else {
        if (
          appContext?.state?.query?.type === 'doctors' &&
          doctorsTabsRef.current
        ) {
          doctorsTabsRef.current.parentElement?.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        } else if (
          appContext?.state?.query?.type === 'locations' &&
          locationsTabsRef.current
        ) {
          locationsTabsRef.current.parentElement?.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
      }
    }

    useEffect(() => {
      setSortBy(appContext?.state?.query?.sort || settings.sort.default)
    }, [appContext?.state?.query?.sort])

    useEffect(() => {
      updatePagination()
    }, [appContext?.state?.doctors, appContext?.state?.locations])

    useEffect(() => {
      setActive({
        doctors: null,
        locations: null,
      })
    }, [appContext?.state?.query])

    useEffect(() => {
      scrollIntoView()
    }, [active])

    useImperativeHandle(
      ref,
      () => {
        return {
          setActiveDoctor(id: number | null) {
            setActive({
              ...active,
              doctors: id,
            })
          },
          setActiveLocation(id: number | null) {
            setActive({
              ...active,
              locations: id,
            })
          },
        }
      },
      []
    )

    const [isOpen, _setIsOpen] = useAtom(requsetCallbackModalAtom)
    const [_, _setDoctor] = useAtom(requestCallbackModalDoctorSelectedAtom)

    if (siteInfo.isUS) {
      return (
        <div className="flex flex-col h-full">
          {!showLocationDetail && (
            <div>
              {paginatedDoctors?.map((doctor: Doctor) => {
                const doctorId = doctor.id
                const matchingLocationId = locations?.find((location) => {
                  const foundDoctor = location.doctors.find(
                    (doctor) => doctor === doctorId
                  )
                  return !!foundDoctor
                })?.id
                const matchingIndex = doctor?.locations.indexOf(
                  matchingLocationId ?? 0
                )

                return (
                  <MListItem
                    key={doctor.id}
                    expandable={true}
                    objectId={doctor.id}
                    avatar={doctor.avatar}
                    name={[doctor.title, doctor.subtitle]}
                    address={doctor.address}
                    distance={doctor.distance}
                    specialist={doctor.specialist}
                    locationTitle={doctor.subtitle}
                    locationPhone={doctor.locationPhone}
                    isActive={active.doctors === doctor.id}
                    type="doctor"
                    detailsLink={`/doctors/${doctor.slug}/about`}
                    slug={doctor.slug}
                    directionsLink={
                      'https://maps.google.com?q=' +
                      encodeURIComponent(doctor.address)
                    }
                    onActive={(id: number) => handleItemActive(id, 'doctors')}
                    doctorCustomReviewLink={doctor.doctorCustomReviewLink}
                    doctorCustomReviewText={doctor.doctorCustomReviewText}
                    doctorGoogleReviewLink={doctor.doctorGoogleReviewLink}
                    doctorReviewId={doctor.doctorReviewId}
                    doctorReviewType={doctor.doctorReviewType}
                    requestCallbackDoctorComponent={
                      <div
                        className={`cursor-pointer text-sm font-normal text-center request-callback ${
                          appContext &&
                          isRecentlyRequestedDoctor(appContext, doctor.slug) &&
                          'opacity-50 pointer-events-none'
                        }`}
                        style={{ display: 'none' }}
                        onClick={() => {
                          if (
                            appContext &&
                            isRecentlyRequestedDoctor(appContext, doctor.slug)
                          )
                            return
                          _setIsOpen(!isOpen)
                          _setDoctor(doctor)
                        }}
                      >
                        Request a callback instead
                      </div>
                    }
                  />
                )
              })}
              <MListPagination
                pages={pagination.doctors.pages}
                page={pagination.doctors.page}
                onChange={handleDoctorsPaginationChange}
              />
              {!loading && !appContext?.state.doctors.length && <ANoResults />}
            </div>
          )}
          {showLocationDetail && locationDetail && (
            <div className="md:flex-grow md:overflow-y-auto md:h-0">
              <CLocationDetail
                location={locationDetail}
                onBack={handleCloseLocationDetail}
              />
            </div>
          )}
        </div>
      )
    }

    return (
      <div className="flex flex-col h-full">
        <div>
          <div className="p-4 mt-2">
            {settings.search === 'regions' && (
              <MDropdown
                label={t('region')}
                full={true}
                placeholder={t('select-a-region') ?? ''}
                options={regionsOptions}
                onChange={handleRegionChange}
              />
            )}
            {settings.search !== 'regions' && (
              <MSearchInput onSubmit={handleSubmitSearch} />
            )}
          </div>
        </div>
        {settings.search === 'geocoding' && !showLocationDetail && (
          <div className="flex flex-col items-start px-4 pb-2 border-b border-gray-200">
            {settings.sort.options.length > 1 && (
              <div className="flex items-center h-8 font-semibold text-gray-800 font-body font-base">
                <p className="mr-4 ">{t('sort-by')}:&nbsp;</p>

                <div className="flex gap-5">
                  {settings.sort.options
                    .filter((val) => val.label !== 'tiered')
                    .map((option) => (
                      <ASortByRadio
                        name="sort_by"
                        key={option.label}
                        value={option.label}
                        label={t(option.label)}
                        isChecked={sortBy === option.label}
                        onChange={handleSortChange}
                      />
                    ))}
                </div>
              </div>
            )}
            {radiusOptions.length > 1 && (
              <MDropdown
                label={t('radius')}
                options={radiusOptions}
                onChange={handleRadiusChange}
              />
            )}
          </div>
        )}
        {!showLocationDetail && (
          <div className="relative flex-grow">
            <MTabs onTabChange={handleTabChange}>
              {typesOptions.includes('doctors') && (
                <ATab
                  ref={doctorsTabsRef}
                  handle="doctors"
                  title={`${t('doctors')} (${
                    appContext?.state?.doctors &&
                    appContext?.state.doctors.length < 100
                      ? appContext?.state.doctors.length
                      : '99+'
                  })`}
                  active={appContext?.state?.query?.type === 'doctors'}
                >
                  {paginatedDoctors?.map((doctor: Doctor) => {
                    return (
                      <MListItem
                        key={doctor.id}
                        expandable={true}
                        objectId={doctor.id}
                        avatar={doctor.avatar}
                        name={[doctor.title, doctor.subtitle]}
                        address={doctor.address}
                        distance={doctor.distance}
                        specialist={doctor.specialist}
                        locationTitle={doctor.subtitle}
                        isActive={active.doctors === doctor.id}
                        type="doctor"
                        detailsLink={`/doctors/${doctor.slug}/about`}
                        slug={doctor.slug}
                        directionsLink={
                          'https://maps.google.com?q=' +
                          encodeURIComponent(doctor.address)
                        }
                        onActive={(id: number) =>
                          handleItemActive(id, 'doctors')
                        }
                      />
                    )
                  })}
                  <MListPagination
                    pages={pagination.doctors.pages}
                    page={pagination.doctors.page}
                    onChange={handleDoctorsPaginationChange}
                  />
                  {!loading && !appContext?.state?.doctors?.length && (
                    <ANoResults />
                  )}
                </ATab>
              )}
              {typesOptions.includes('locations') && (
                <ATab
                  ref={locationsTabsRef}
                  handle="locations"
                  title={`${t('locations')} (${
                    appContext?.state?.locations &&
                    appContext?.state.locations.length < 100
                      ? appContext?.state.locations.length
                      : '99+'
                  })`}
                  active={appContext?.state?.query?.type === 'locations'}
                >
                  {paginatedLocations?.map((location: Location) => {
                    return (
                      <MListItem
                        key={location.id}
                        expandable={true}
                        objectId={location.id}
                        avatar={location.avatar}
                        name={[location.title]}
                        address={location.address}
                        distance={location.distance}
                        isActive={active.locations === location.id}
                        type="location"
                        detailsLink={`/locations/${location.slug}/about`}
                        directionsLink={
                          'https://maps.google.com?q=' +
                          encodeURIComponent(location.address)
                        }
                        onActive={(id: number) =>
                          handleItemActive(id, 'locations')
                        }
                      />
                    )
                  })}
                  <MListPagination
                    pages={pagination.locations.pages}
                    page={pagination.locations.page}
                    onChange={handleLocationsPaginationChange}
                  />
                  {!loading && !appContext?.state?.locations?.length && (
                    <ANoResults />
                  )}
                </ATab>
              )}
            </MTabs>
          </div>
        )}
        {showLocationDetail && locationDetail && (
          <div className="md:flex-grow md:overflow-y-auto md:h-0">
            <CLocationDetail
              location={locationDetail}
              onBack={handleCloseLocationDetail}
            />
          </div>
        )}
      </div>
    )
  }
)
