import classNames from 'classnames'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import css from 'styled-jsx/css'
import { ASvg } from 'ui'
import SvgPinIcon from 'ui/svg/icons/fill/pin.svg'
import Mapbox from 'mapbox-gl'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import useSettings from '../../hooks/use-settings'

export type CMapRef = {
  selectMultipleMarkers: (ids: Array<number>) => void
  fitMarkers: () => void
}

type Marker = {
  id?: number | null
  lat: number
  lng: number
  count?: number | null
  selected?: boolean
  clickable?: boolean
}

type Props = {
  markers: Array<Marker>
  navigation?: boolean
  onMarkerClick?: (type: string, id: number) => void
  isVisible?: boolean
}

const defaultProps = {
  navigation: false,
  markers: [],
  isVisible: true,
}

const CMap = forwardRef(
  ({ markers, onMarkerClick, navigation, isVisible }: Props, ref) => {
    const settings = useSettings()
    const siteInfo = useSiteInfo()

    const mapRef = useRef<Mapbox.Map | null>(null)
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const markerRef = useRef<HTMLDivElement>(null)

    const markerArray = useRef<Array<Mapbox.Marker>>([])

    const staticUrl = useMemo(() => {
      if (markers.length) {
        const marker = markers[0]
        const color =
          siteInfo?.layout === CONST.SITE_LAYOUT.ICL ? '10A8BA' : 'F86E83'

        const params = [
          '/styles/v1/jngstaar/ckkdwrut30pnx17n3dc18ehya/static',
          `/pin-l+${color}(${marker.lng},${marker.lat})`,
          `/${marker.lng},${marker.lat},15,0`,
          '/1280x255@2x',
          `?access_token=${process.env.NEXT_PUBLIC_DOCTORS_MAPBOX_API_KEY}`,
        ]

        return 'https://api.mapbox.com' + params.join('')
      }

      return ''
    }, [markers])

    const clearMarkers = () => {
      markerArray.current.forEach((marker) => {
        marker.remove()
      })
      markerArray.current = []
    }

    const setMarkerState = (marker: Mapbox.Marker, selected: boolean) => {
      const markerElement = marker.getElement()
      const layout = siteInfo?.layout

      if (selected) {
        markerElement.classList.remove('bg-neutral-white')
        markerElement.classList.add(
          layout === CONST.SITE_LAYOUT.ICL ? 'bg-primary-teal' : 'bg-pink-500'
        )
        markerElement.classList.add('z-10')
        markerElement.classList.remove(
          layout === CONST.SITE_LAYOUT.ICL
            ? 'text-primary-teal'
            : 'text-pink-500'
        )
        markerElement.classList.add('text-neutral-white')
      } else {
        markerElement.classList.remove(
          layout === CONST.SITE_LAYOUT.ICL ? 'bg-primary-teal' : 'bg-pink-500'
        )
        markerElement.classList.add('bg-neutral-white')
        markerElement.classList.remove('z-10')
        markerElement.classList.remove('text-neutral-white')
        markerElement.classList.add(
          layout === CONST.SITE_LAYOUT.ICL
            ? 'text-primary-teal'
            : 'text-pink-500'
        )
      }
    }

    const selectMultipleMarkers = (ids: Array<number>) => {
      const selectedMarkers: Array<Mapbox.Marker> = []
      markerArray.current.forEach((marker) => {
        const markerElement = marker.getElement()
        const id = parseInt(
          markerElement.getAttribute('data-object-id') ?? '',
          10
        )

        if (ids.includes(id)) {
          setMarkerState(marker, true)
          selectedMarkers.push(marker)
        } else {
          setMarkerState(marker, false)
        }
      })
      fitMarkers(selectedMarkers)
    }

    const addMarker = (data: Marker) => {
      if (!data.lng || !data.lat) {
        return
      }

      if (markerRef.current && mapRef.current) {
        const element = markerRef.current.cloneNode(true) as HTMLDivElement
        const countElement = element.querySelector('.c-map__marker-count')
        if (countElement) {
          if (data.count) {
            countElement.innerHTML = data.count.toString()
          } else {
            countElement.parentNode?.removeChild(countElement)
          }
        }

        const index = markerArray.current.length
        const newMarker = new Mapbox.Marker(element)
          .setLngLat([data.lng, data.lat])
          .addTo(mapRef.current)

        markerArray.current.push(newMarker)

        element.setAttribute('data-index', index.toString())
        if (data.id) {
          element.setAttribute('data-object-id', data.id.toString())
        }

        if (data.selected) {
          setMarkerState(newMarker, true)
        }

        if (data.clickable !== false) {
          element.addEventListener('click', (event) => {
            event.preventDefault()
            if (data.id) {
              if (onMarkerClick instanceof Function) {
                onMarkerClick('marker', data.id)
              }
              selectMultipleMarkers([data.id])
            }
          })
        } else {
          newMarker.getElement().classList.remove('cursor-pointer')
        }
      }
    }

    const fitMarkers = (markers: Array<Mapbox.Marker> | null = null) => {
      let mrk: Array<Mapbox.Marker> = []
      if (markers) {
        mrk = markers
      } else {
        mrk = markerArray.current
      }

      if (mrk.length && mapRef.current) {
        const bounds = new Mapbox.LngLatBounds()
        mrk.forEach((marker) => {
          const lngLat = marker.getLngLat()
          bounds.extend([lngLat.lng, lngLat.lat])
        })
        mapRef.current.fitBounds(bounds, {
          padding: 100,
          maxZoom: 12,
        })
      }
    }

    const refreshMarkers = useCallback(() => {
      clearMarkers()
      markers.forEach((marker: Marker) => {
        addMarker(marker)
      })
      fitMarkers()
    }, [markers])

    const centerDefault = () => {
      if (mapRef.current) {
        mapRef.current.flyTo({
          center: [settings.map.lng, settings.map.lat],
          zoom: settings.map.zoom,
        })
      }
    }

    useEffect(() => {
      refreshMarkers()
      if (!markers.length) {
        centerDefault()
      }
    }, [markers])

    useEffect(() => {
      if (isVisible && mapRef.current) {
        console.log('mapRef.current', mapRef.current)
        mapRef.current.resize()
      }
    }, [isVisible])

    useEffect(() => {
      Mapbox.accessToken = process.env.NEXT_PUBLIC_DOCTORS_MAPBOX_API_KEY ?? ''

      if (navigation && !mapRef.current) {
        const map = new Mapbox.Map({
          container: mapContainerRef.current as HTMLDivElement,
          style: 'mapbox://styles/jngstaar/ckkdwrut30pnx17n3dc18ehya',
          center: [settings.map.lng, settings.map.lat],
          zoom: settings.map.zoom,
        })

        map.addControl(
          new Mapbox.NavigationControl({
            showCompass: false,
          }),
          'bottom-right'
        )

        mapRef.current = map
      }
    }, [])

    useImperativeHandle(
      ref,
      () => {
        return {
          selectMultipleMarkers(ids: Array<number>) {
            selectMultipleMarkers(ids)
          },
          fitMarkers() {
            fitMarkers()
          },
        }
      },
      []
    )

    /* eslint-disable @next/next/no-img-element */
    return (
      <div className="relative w-full h-full">
        {!navigation && (
          <img
            alt=""
            className="block w-full h-full object-cover"
            src={staticUrl}
          />
        )}
        {navigation && <div ref={mapContainerRef} className="w-full h-full" />}
        <div className="hidden">
          <div
            ref={markerRef}
            className={classNames(
              'c-map__marker bg-neutral-white rounded-full flex items-center justify-center cursor-pointer w-6 h-6',
              {
                'text-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
              }
            )}
          >
            <ASvg className="w-6 h-6 fill-current" svg={SvgPinIcon} />
            <div className="c-map__marker-count absolute bg-neutral-black rounded-full text-neutral-white top-0 font-body font-base font-semibold flex items-center justify-center" />
          </div>
        </div>
        <style jsx>{styles}</style>
      </div>
    )
  }
)

const styles = css`
  .c-map__marker {
    box-shadow: 4px 1px 12px rgba(4, 29, 62, 0.0898711);
    height: 64px;
    width: 64px;
  }

  .c-map__marker-count {
    height: 24px;
    width: 24px;
    right: -7px;
  }
`

CMap.defaultProps = defaultProps

export { CMap }
