import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import TagManager from 'react-gtm-module'
import {
  Dispatch,
  FormEvent,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AppContext } from 'doctors/context/app'
import useFormie from 'doctors/hooks/use-formie'
import useSettings from 'doctors/hooks/use-settings'
import { AButton } from 'ui'
import SvgCheckTrueIcon from 'ui/svg/icons/fill/check-true.svg'
import SvgCheckFalseIcon from 'ui/svg/icons/fill/check-false.svg'
import { AFormInput } from 'doctors/components/atoms/a-form-input'
import { AFormTextarea } from 'doctors/components/atoms/a-form-textarea'
import { CContactFormModal } from 'doctors/components/organisms/c-contact-form-modal'
import * as CONST from 'ui/constants/index'
import { getIsTestEmail, useSiteInfo } from 'shared'
import { CraftDoctor, CraftLocation } from 'doctors/craft-types'

type Props = {
  doctor?: CraftDoctor
  location?: CraftLocation
  name?: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
}

const defaultProps = {
  doctor: undefined,
  location: undefined,
  name: '',
  step: 1,
  setStep: () => {},
}

const CContactForm = ({ doctor, location, name, step, setStep }: Props) => {
  const appContext = useContext(AppContext)
  const formie = useFormie()
  const siteInfo = useSiteInfo()
  const router = useRouter()
  const settings = useSettings()
  const { t } = useTranslation('common')

  const [errors, setErrors] = useState<any>(undefined)

  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [zipcode, setZipcode] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)

  const SvgCheckIcon = isSubscribed ? SvgCheckTrueIcon : SvgCheckFalseIcon

  useEffect(() => {
    setStep(1)
  }, [])

  const cancelForm = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const url = `/${doctor ? 'doctors' : 'locations'}/${
      router.query.slug
    }/about`
    router.push(url, undefined, { shallow: true })

    setStep(1)
  }

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Validate the custom checkbox
    if (!isSubscribed && siteInfo.isUS) {
      setErrors({ message: [t('contact-subscribe-required')] })
      return
    }

    handleDataLayer()
    handleAutopilot()

    const res = await formie.send({
      doctor,
      location,
      lastName,
      email,
      phone,
      message,
    })

    if (!res.success) {
      setErrors(res.errors)
    } else {
      setErrors(undefined)
      setStep(2)
    }
  }

  const pushSegment = (event: string, data: any, identifyData?: any) => {
    if (!window.analytics || !siteInfo.isUS) {
      return
    }
    window.analytics.track(event, data)
    identifyData && window.analytics.identify(data.email, identifyData)
  }

  const handleMultipleDoctors = () => {
    pushSegment('Additional Doctor Contact Intention', {
      doctar_name: doctor!.title,
      specialist_doctor: doctor!.doctorSpecialist,
      doctor_clinic: doctor!.doctorLocations![0].title,
      doctor_address: doctor!.doctorLocations![0].locationAddress,
      review_type: doctor!.doctorReviewType ? doctor!.doctorReviewType : null,
      review_available: !!doctor!.doctorReviewId,
    })
    setShowModal(true)
  }

  const handleDataLayer = () => {
    if (doctor?.id) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'contactFormSubmit',
          event_category: 'Contact Form Submissions',
          event_action: doctor.title,
          event_label: window.location.href,
        },
      })
      pushSegment(
        'Doctor Contact Submitted',
        {
          doctar_name: doctor.title,
          specialist_doctor: doctor.doctorSpecialist,
          doctor_clinic: doctor.doctorLocations![0].title,
          doctor_address: doctor.doctorLocations![0].locationAddress,
          review_type: doctor.doctorReviewType ? doctor.doctorReviewType : null,
          review_available: !!doctor.doctorReviewId,
          subscribe: isSubscribed,
          user_message: message,
        },
        { email, full_name: lastName, phone }
      )
    } else if (location?.id) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'contactFormSubmit',
          event_category: 'Contact Form Submissions',
          event_action: location!.title,
          event_label: window.location.href,
        },
      })
      pushSegment(
        'Location Contact Form Submitted',
        {
          clinic_name: location.title,
          clinic_address: location.locationAddress,
          subscribe: isSubscribed,
          user_message: message,
        },
        { email, full_name: lastName, phone }
      )
    }
  }

  const handleAutopilot = async () => {
    const isTestEmail = getIsTestEmail(email)
    const isUsOrUk = Boolean(siteInfo.isUS || siteInfo.isUK)
    if (!isSubscribed || !isUsOrUk || isTestEmail) {
      return
    }

    const body = {
      contact: {
        Email: email,
        MailingPostalCode: zipcode,
        MailingCountry: siteInfo.isUK ? 'United Kingdom' : siteInfo.name,
        custom: {
          'float--FaD--Search--Lat': appContext?.state.query.lat,
          'float--FaD--Search--Lng': appContext?.state.query.lng,
          'integer--FaD--Search--Radius': appContext?.state.query.radius,
          'string--FaD--Doctor--Contacted': appContext?.state.doctor.title,
        },
      },
    }

    fetch(
      process.env.NEXT_PUBLIC_DOCTORS_API_PROXY_URL +
        '/autopilot/captureUser.js',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    ).catch(() => {})
  }

  if (siteInfo.isUS) {
    return (
      <div className="font-body">
        {step === 1 && (
          <>
            <h4 className="text-xl mb-5 font-semibold">
              {doctor?.freeConsultation
                ? t('set-free-consultation')
                : t('set-consultation')}
            </h4>
            <div className="text-sm">
              {doctor?.customFormVerbiage || t('form-info')}
            </div>

            <form onSubmit={submitForm}>
              <div className="flex flex-wrap -mx-3">
                <AFormInput
                  placeholder={t('full-name') ?? ''}
                  required={true}
                  value={lastName}
                  version="rounded"
                  onChangeHandler={setLastName}
                />
                <AFormInput
                  placeholder={t('email-address') ?? ''}
                  required={true}
                  type="email"
                  value={email}
                  version="rounded"
                  onChangeHandler={setEmail}
                />
                <AFormInput
                  placeholder={t('phone-number') ?? ''}
                  required={true}
                  value={phone}
                  version="rounded"
                  onChangeHandler={setPhone}
                />
                <AFormInput
                  placeholder={t('zip-code') ?? ''}
                  required={true}
                  value={zipcode}
                  version="rounded"
                  onChangeHandler={setZipcode}
                />
                <AFormTextarea
                  placeholder={t('message') ?? ''}
                  value={message}
                  onChangeHandler={setMessage}
                  version="rounded"
                />
              </div>

              {settings.contact.subscribe && (
                <div
                  className="cursor-pointer flex mt-7"
                  onClick={() => {
                    setErrors(undefined)
                    setIsSubscribed(!isSubscribed)
                  }}
                >
                  <SvgCheckIcon className="flex-shrink-0 fill-current mr-4 text-gray-800 w-3 h-3" />
                  <p
                    className="text-xs text-gray-700 w-full [&>a]:underline [&>a]:text-primary-dark-teal"
                    dangerouslySetInnerHTML={{
                      __html: t('form-subscription-text') ?? '',
                    }}
                  />
                </div>
              )}

              {errors?.message?.map((message: string) => (
                <div className="text-base text-center font-semibold leading-7 text-gray-800 text-secondary-red mt-4">
                  {t(message)}
                </div>
              ))}

              <div className="flex flex-col gap-4 items-center mt-8">
                <AButton
                  className="w-full max-w-[280px]"
                  color={siteInfo?.layout === 'icl' ? 'teal' : 'pink'}
                  size="lg"
                  title={t('submit') ?? ''}
                  type="submit"
                >
                  <>{t('submit')}</>
                </AButton>
                <button
                  title={t('cancel') ?? ''}
                  type="button"
                  onClick={cancelForm}
                >
                  {t('cancel-and-return')}
                </button>
              </div>
            </form>

            <div className="text-sm text-gray-700 font-semibold leading-4 mt-8 text-center">
              {t('form-data-provide')}
              <br />
              {t('staar-more-info')}{' '}
              <a href={t('privacy-policy-url') ?? ''} className="underline">
                {t('privacy-policy')}
              </a>{' '}
              {t('and')}{' '}
              <a href={t('terms-of-use-url') ?? ''} className="underline">
                {t('terms-of-use')}
              </a>
              .
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div className="text-base font-semibold leading-7 text-gray-400 text-center lg:text-left">
              {t('message-submitted-info')}{' '}
              <span className="text-gray-800"> {name}. </span>
            </div>

            {!!doctor && settings.contact.modal && (
              <>
                <div className="border border-gray-400 my-10" />
                <h2 className=" lg:text-left font-body font-light mt-4 text-2xl md:text-3xl text-gray-700 text-center">
                  {t('contact-multiple-heading')}
                </h2>
                <div className="text-base font-semibold leading-7 mb-4 text-gray-400 text-center lg:text-left">
                  {t('contact-multiple-subheading')}
                </div>
                <div className="lg:flex">
                  <AButton
                    size="lg"
                    style="outline"
                    title={t('contact-multiple-button') ?? ''}
                    type="button"
                    onClick={handleMultipleDoctors}
                  >
                    <>{t('contact-multiple-button')}</>
                  </AButton>
                </div>
                <CContactFormModal
                  data={{
                    lastName,
                    email,
                    phone,
                    message,
                    isSubscribed,
                  }}
                  open={showModal}
                  onClose={() => setShowModal(false)}
                />
              </>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <div className="font-body">
      {step === 1 && (
        <>
          <div className="text-base font-semibold leading-7 text-gray-800 text-center lg:text-left">
            {doctor?.customFormVerbiage || t('form-info')}
          </div>

          {errors?.message?.map((message: string) => (
            <div className="text-base font-semibold leading-7 text-gray-800 text-center lg:text-left text-secondary-red mt-2">
              {t(message)}
            </div>
          ))}

          <form onSubmit={submitForm}>
            <div className="flex flex-wrap -mx-3">
              <AFormInput
                placeholder={t('full-name') ?? ''}
                required={true}
                value={lastName}
                version="full"
                onChangeHandler={setLastName}
              />
              <AFormInput
                placeholder={t('email-address') ?? ''}
                required={true}
                type="email"
                value={email}
                version="half"
                onChangeHandler={setEmail}
              />
              <AFormInput
                placeholder={t('phone-number') ?? ''}
                required={true}
                value={phone}
                version="half"
                onChangeHandler={setPhone}
              />
              <AFormTextarea
                placeholder={t('message') ?? ''}
                value={message}
                onChangeHandler={setMessage}
              />
            </div>

            {settings.contact.subscribe && (
              <div
                className="bg-gray-300 cursor-pointer flex mt-10 p-5"
                onClick={() => setIsSubscribed(!isSubscribed)}
              >
                <SvgCheckIcon className="flex-shrink-0 fill-current mr-4 text-primary-teal w-6 h-6" />
                <p
                  className="text-xs text-gray-700 w-full [&>a]:underline [&>a]:text-primary-dark-teal"
                  dangerouslySetInnerHTML={{
                    __html: t('form-subscription-text') ?? '',
                  }}
                />
              </div>
            )}

            <div className="flex flex-col lg:flex-row lg:gap-4 items-center mt-8">
              <AButton
                className="max-w-md w-full lg:w-auto"
                color={
                  siteInfo?.layout === CONST.SITE_LAYOUT.ICL ? 'teal' : 'pink'
                }
                size="lg"
                title={t('submit') ?? ''}
                type="submit"
              >
                <>{t('submit')}</>
              </AButton>
              <AButton
                className="mt-4 lg:mt-0 max-w-md w-full lg:w-auto"
                color="black"
                size="lg"
                style="outline"
                title={t('cancel') ?? ''}
                type="button"
                onClick={cancelForm}
              >
                <>{t('cancel')}</>
              </AButton>
            </div>
          </form>

          <div className="text-sm text-gray-700 font-semibold leading-4 mt-8 text-center lg:text-left">
            {siteInfo.isGE && (
              <>
                <div
                  className="text-gray-700 w-full [&>p>a]:underline [&>p>a]:text-primary-teal [&>p>a:hover]:text-primary-dark-teal"
                  dangerouslySetInnerHTML={{ __html: t('form-note') }}
                />
                <br />
              </>
            )}
            {t('form-data-provide')}
            <br />
            {t('staar-more-info')}{' '}
            <a
              href={t('privacy-policy-url') ?? ''}
              className={classNames({
                'text-pink-500 hover:text-pink-600':
                  siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                'text-primary-teal hover:text-primary-dark-teal':
                  siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
              })}
            >
              {t('privacy-policy')}
            </a>{' '}
            {t('and')}{' '}
            <a
              href={t('terms-of-use-url') ?? ''}
              className={classNames({
                'text-pink-500 hover:text-pink-600':
                  siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                'text-primary-teal hover:text-primary-dark-teal':
                  siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
              })}
            >
              {t('terms-of-use')}
            </a>
            .
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className="text-base font-semibold leading-7 text-gray-400 text-center lg:text-left">
            {t('message-submitted-info')}{' '}
            <span className="text-gray-800"> {name}. </span>
          </div>

          {!!doctor && settings.contact.modal && (
            <>
              <div className="border border-gray-400 my-10" />
              <h2 className=" lg:text-left font-body font-light mt-4 text-2xl md:text-3xl text-gray-700 text-center">
                {t('contact-multiple-heading')}
              </h2>
              <div className="text-base font-semibold leading-7 mb-4 text-gray-400 text-center lg:text-left">
                {t('contact-multiple-subheading')}
              </div>
              <div className="lg:flex">
                <AButton
                  size="lg"
                  style="outline"
                  title={t('contact-multiple-button') ?? ''}
                  type="button"
                  onClick={handleMultipleDoctors}
                >
                  <>{t('contact-multiple-button')}</>
                </AButton>
              </div>
              <CContactFormModal
                data={{ lastName, email, phone, message, isSubscribed }}
                open={showModal}
                onClose={() => setShowModal(false)}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

CContactForm.defaultProps = defaultProps

export { CContactForm }
