import { useEffect } from 'react'
/* eslint-disable react/no-unescaped-entities */

import { AButton, MModal } from 'ui'
import { format } from 'date-fns'

import { atom, useAtom } from 'jotai'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import { Doctor } from '../../context/reducers/doctors'
import { RequestCallBackForm } from './c-request-callback-form'

export const requsetCallbackModalAtom = atom<boolean>(false)
export const requestCallbackModalDoctorSelectedAtom = atom<Doctor | undefined>(
  undefined
)

type FormState = {
  step: number
  contactMethod: string | number
  date: string
  time: string
}
export const requestCallbackFormState = atom<FormState>({
  step: 1,
  contactMethod: '',
  date: '',
  time: '',
})

export const CRequestCallbackModal = () => {
  const [isOpen, setIsOpen] = useAtom(requsetCallbackModalAtom)
  const [formState, _setFormState] = useAtom(requestCallbackFormState)
  const [doctor, _setDoctor] = useAtom(requestCallbackModalDoctorSelectedAtom)
  const handleOnClose = () => {
    _setDoctor(undefined)
    _setFormState((prevState) => {
      return {
        ...prevState,
        step: 1,
        emailAddress: '',
        phoneNumber: '',
        zipCode: '',
        contactMethod: '',
        date: '',
        time: '',
      }
    })
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(document.body)
    }

    return () => {
      enableBodyScroll(document.body)
    }
  }, [isOpen])
  return (
    <MModal open onClose={handleOnClose} className={'static'}>
      <>
        <div className={'min-h-[400px]'}>
          <div className="px-0 md:px-5">
            <h2
              className="font-body text-primary-dark-teal"
              style={{ lineHeight: '38.98px', fontSize: '32px' }}
            >
              Request a Callback
            </h2>

            {formState.step !== 2 && <RequestCallBackForm />}

            {formState.step === 2 && (
              <div
                className={'flex flex-col w-full justify-center items-center'}
              >
                <div className="text-3xl font-bold drop-shadow-lg shadow-black text-center mb-5 mt-10">
                  Success!
                </div>

                <div className="text-xl font-semibold leading-7  text-center ">
                  Your request has been submitted.
                  <br />
                  {`You will be contacted by an EVO Advisor by`}
                  <br />
                  {`${formState.contactMethod} on`}
                </div>

                <div className="text-3xl  leading-7 shadow-black text-center mt-10 mb-10 ">
                  {`${format(new Date(formState.date), 'EEEE, MMM dd, yyy')} `}
                  <br />
                  <br />
                  {`at ${formState.time}`}
                </div>

                <div className={'grid grid-cols-1 mb-10'}>
                  <button
                    className=" mt-5 font-normal text-sm min-w-[95px]"
                    onClick={(e) => {
                      e.preventDefault()
                      window.open('https://calendar.google.com')
                    }}
                  >
                    Add to Google Calendar
                  </button>

                  <button
                    className=" mt-5 font-normal text-sm min-w-[95px]"
                    onClick={(e) => {
                      e.preventDefault()
                      window.open('https://www.icloud.com/calendar')
                    }}
                  >
                    Add to Apple calendar
                  </button>
                </div>

                <div className="mt-5 text-center md:w-full  md:px-24">
                  <AButton className="w-full" size="lg" onClick={handleOnClose}>
                    <>Close Form</>
                  </AButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </MModal>
  )
}
