import { AHyperlink } from 'ui'
import { useTranslation } from 'next-i18next'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { CraftDoctorLocation } from '../../craft-types'

type Props = {
  locations: Array<CraftDoctorLocation>
}

export const CDetailsLocations = ({ locations }: Props) => {
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  return (
    <div className="w-full font-body">
      <div className="font-semibold text-2xl text-color-gray-900 mb-10 text-center lg:text-left">
        {locations.length}{' '}
        {locations.length === 1 ? t('location') : t('locations')}
      </div>
      <div className="flex flex-wrap -mt-12">
        {locations.map((location: CraftDoctorLocation, n: number) => {
          return (
            <div key={n} className="w-full sm:w-2/4 mt-12">
              <div className="max-w-[160px] w-full font-semibold">
                <div className="text-gray-900 leading-6">{location?.title}</div>
                <div
                  className="text-gray-800 leading-7"
                  dangerouslySetInnerHTML={{
                    __html:
                      location?.locationAddress?.replace(/\n/, '<br>') ?? '',
                  }}
                />
                <div className="text-gray-800 leading-7">
                  {location?.locationPhone?.url}
                </div>
              </div>
              <AHyperlink
                className="mt-2"
                type="link"
                color={
                  siteInfo?.layout === CONST.SITE_LAYOUT.ICL ? 'teal' : 'pink'
                }
                href={`/locations/${location?.slug}/about`}
                shallow={true}
              >
                <span>{t('view-details')}</span>
              </AHyperlink>
            </div>
          )
        })}
      </div>
    </div>
  )
}
