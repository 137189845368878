import classNames from 'classnames'
import { Children, ReactNode, isValidElement } from 'react'
import css from 'styled-jsx/css'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'

type Props = {
  children: Array<ReactNode>
  onTabChange: (handle: string) => void
}

export const MTabs = ({ children, onTabChange }: Props) => {
  const siteInfo = useSiteInfo()

  const handleChangeTab = (handle: string) => {
    if (onTabChange instanceof Function) {
      onTabChange(handle)
    }
  }

  return (
    <div className="flex flex-col w-full md:absolute md:top-0 md:left-0 md:bottom-0">
      <div className="flex-shrink-0 px-4 border-b border-gray-200">
        <ul className="flex md:justify-start md:-mx-8">
          {Children.map(children, (child) => {
            if (!isValidElement(child)) {
              return <></>
            }

            return (
              <li
                key={child.props.handle}
                className={classNames(
                  'm-tabs__tab cursor-pointer border-b-4 font-body font-bold text-sm uppercase py-3 md:mx-8 flex-grow md:flex-grow-0 transition-colors duration-200 ease-out select-none',
                  {
                    'text-gray-900': child.props.active,
                    'text-gray-400 border-transparent': !child.props.active,
                    'border-primary-teal':
                      child.props.active &&
                      siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                    'border-pink-500':
                      child.props.active &&
                      siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                  }
                )}
                onClick={() => handleChangeTab(child.props.handle)}
              >
                <div className="w-full text-center md:text-left">
                  {child.props.title}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="md:overflow-y-auto md:min-h-0 md:flex-grow">
        {children}
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

const styles = css`
  .m-tabs__tab {
    border-inline-end: 1px solid #cfcfcf;
  }

  .m-tabs__tab:last-child {
    border-inline-end: none;
  }

  @media (min-width: 768px) {
    .m-tabs__tab {
      border-inline-end: none;
    }
  }
`
