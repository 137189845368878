import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import * as CONST from 'ui/constants/index'
import Image from 'next/image'
import StarRatings from 'react-star-ratings'
import { useTranslation } from 'next-i18next'
import { useSiteInfo } from 'shared'
import { CraftDoctor, CraftLocation } from '../../craft-types'

type ReviewData = {
  link?: string
  rating?: number
  reviewCount?: number
  type?: string
  text?: string
}

type Props = {
  doctor?: CraftDoctor
  location?: CraftLocation
  doctorCustomReviewLink?: string | null
  doctorCustomReviewText?: string | null
  doctorGoogleReviewLink?: string | null
  doctorReviewId?: string | null
  doctorReviewType?: string | null
  isDetail?: boolean
}

export const MReviews = ({
  doctorReviewId,
  doctorReviewType,
  doctorGoogleReviewLink,
  doctorCustomReviewText,
  doctorCustomReviewLink,
  location,
  isDetail,
}: Props) => {
  const siteInfo = useSiteInfo()
  const { t } = useTranslation('common')

  const [reviewData, setReviewData] = useState<ReviewData | null>(null)

  const review = useMemo(() => {
    if (doctorReviewId) {
      return {
        id: doctorReviewId,
        type: doctorReviewType,
        googleLink: doctorGoogleReviewLink,
        customText: doctorCustomReviewText,
        customLink: doctorCustomReviewLink,
      }
    } else if (location && location.id) {
      return {
        id: location.locationReviewId,
        type: location.locationReviewType,
        googleLink: location.locationGoogleReviewLink,
        customText: location.locationCustomReviewText,
        customLink: location.locationCustomReviewLink,
      }
    } else {
      return null
    }
  }, [doctorReviewId, location])

  const getCustom = () => {
    return {
      text: review?.customText,
      link: review?.customLink,
    }
  }

  const getGoogle = async () => {
    try {
      const res = await fetch('/api/getGoogleReviews', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ placeId: review?.id }),
      }).then((res) => res.json())

      if (!res) {
        return null
      }

      // Google Reviews returns ratings down to the nearest tenth decimal place,
      // so a rating can be anywhere from x.1 -> x.9. Google will display half star
      // ratings for values anywhere from .3 -> .7. A rating of 4.3 needs to display a
      // half star as well as rating of 4.7. Values .0 -> .2 need to round down to the nearest
      // whole number and values .8 -> .9 need to round up to the nearest whole number

      const rating = Number(res.rating)
      const decimal = rating - Math.floor(rating)

      res.rating =
        decimal < 0.3 || decimal > 0.7
          ? Math.round(rating)
          : Math.floor(rating) + 0.5

      res.link = review?.googleLink

      return res
    } catch (error) {
      console.log(error)
    }
  }

  const getYelp = async () => {
    try {
      const res = await fetch('/api/getYelpReviews', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ yelpId: review?.id }),
      }).then((res) => res.json())

      if (!res) {
        return null
      }

      res.link = `https://www.yelp.com/biz/${review?.id}`

      return res
    } catch (error) {
      console.log(error)
    }
  }

  // const handleClick = () => {
  //   if (reviewData?.link) {
  //     const event = new CustomEvent('showExternalModal', {
  //       detail: reviewData.link,
  //     })
  //     window.dispatchEvent(event)
  //   }
  // }

  useEffect(() => {
    ;(async () => {
      let res = null
      if (review?.type?.toLowerCase() === 'custom' && review?.customLink) {
        res = getCustom()
      } else if (review?.type?.toLowerCase() === 'google' && review?.id) {
        res = await getGoogle()
      } else if (review?.type?.toLowerCase() === 'yelp' && review?.id) {
        res = await getYelp()
      }
      setReviewData(res)
    })()
  }, [review])

  if (!reviewData) {
    if (siteInfo.isUS) {
      return <div className="h-[24px]" />
    } else {
      return <></>
    }
  }

  if (siteInfo.isUS) {
    return (
      <div className="mt-4">
        <button
          type="button"
          // onClick={handleClick}
          className={classNames({
            'text-left': !isDetail,
            'text-center': isDetail,
          })}
        >
          <div className="flex items-center mb-2">
            {(reviewData?.rating || reviewData?.rating === 0) && (
              <div className="mb-[2px]">
                <StarRatings
                  rating={reviewData?.rating}
                  starRatedColor="rgb(16 168 186)"
                  starEmptyColor="#d7ded9"
                  starDimension="16px"
                  numberOfStars={5}
                  name="rating"
                  starSpacing="1px"
                />
              </div>
            )}
            {!!reviewData?.reviewCount && (
              <span className="inline-block text-sm text-gray-500 align-middle ml-1">
                ({reviewData?.reviewCount})
              </span>
            )}
          </div>
          <span className="flex gap-2 items-center">
            {!!reviewData?.reviewCount && <>{t('powered-by')}</>}
            {(reviewData?.rating || reviewData?.rating === 0) &&
              reviewData?.reviewCount && (
                <Image
                  className={classNames('max-w-[60px] md:max-w-[66px]', {
                    'max-w-[40px] md:max-w-[46px] mb-1':
                      review?.type?.toLowerCase() === 'yelp',
                  })}
                  src={
                    siteInfo.isUS && review?.type?.toLowerCase() === 'google'
                      ? `/img/${review?.type?.toLowerCase()}/logo-us.png`
                      : `/img/${review?.type?.toLowerCase()}/logo.png`
                  }
                  alt=""
                  width={160}
                  height={80}
                />
              )}
          </span>
          {reviewData?.text && (
            <span
              className={classNames(
                'link font-body font-bold uppercase inline-flex items-center',
                {
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                }
              )}
            >
              {reviewData?.text}
            </span>
          )}
        </button>
      </div>
    )
  }
  /* eslint-disable @next/next/no-img-element */
  return (
    <div className="lg:mt-6 px-12 lg:px-0 self-start">
      <h3 className="text-gray-900 font-semibold leading-10 text-2xl">
        Reviews
      </h3>

      <button
        type="button"
        // onClick={handleClick}
      >
        {(reviewData?.rating || reviewData?.rating === 0) && (
          <img
            className="pb-2 inline-block"
            src={`/img/${review?.type}/rating_${reviewData?.rating}.png`}
            alt=""
          />
        )}
        {!!reviewData?.reviewCount && (
          <span className="pb-2 inline-block text-sm text-gray-500 align-middle">
            ({reviewData?.reviewCount})
          </span>
        )}
        <span>
          {(reviewData?.rating || reviewData?.rating === 0) &&
            reviewData?.reviewCount && (
              <img
                className={classNames({
                  'h-8': review?.type === 'yelp',
                })}
                src={`/img/${review?.type}/logo.png`}
                alt=""
              />
            )}
        </span>
        {reviewData?.text && (
          <span
            className={classNames(
              'link font-body font-bold uppercase inline-flex items-center',
              {
                'text-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
              }
            )}
          >
            {reviewData?.text}
          </span>
        )}
      </button>
    </div>
  )
}
