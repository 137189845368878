import { useAtom } from 'jotai'
import { FormEvent, useState, useRef, useContext } from 'react'
import SvgCheckTrueIcon from 'ui/svg/icons/fill/check-true.svg'
import SvgCheckFalseIcon from 'ui/svg/icons/fill/check-false.svg'
import { AButton, ASvg } from 'ui'
import css from 'styled-jsx/css'
import { getIsTestEmail, useSiteInfo } from 'shared'
import SvgLoadingIcon from 'ui/svg/icons/fill/loading.svg'
import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import { format, set, addMinutes } from 'date-fns'
import TagManager from 'react-gtm-module'
import { ASortByRadio } from '../atoms/a-sort-by-radio'
import { AFormInput } from '../atoms/a-form-input'
import { RequestCallBackFromData } from '../../helpers/formie-request-callback'
import { useFormieRequestCallback } from '../../hooks/use-formie-request-callback'
import { AppContext } from '../../context/app'
import {
  requestCallbackFormState,
  requestCallbackModalDoctorSelectedAtom,
} from './c-request-callback-modal'

function generateTimeOptions() {
  let currentDate = set(new Date(), {
    hours: 7,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  const endDate = set(new Date(), {
    hours: 17,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  const options = []

  while (currentDate <= endDate) {
    const formattedTime = format(currentDate, 'h:mm a')
    options.push({ value: formattedTime, label: `${formattedTime} PST` })
    currentDate = addMinutes(currentDate, 15)
  }

  return options
}
const timeOptions = generateTimeOptions()

export const RequestCallBackForm = () => {
  const siteInfo = useSiteInfo()
  const formie = useFormieRequestCallback()
  const [formState, _setFormState] = useAtom(requestCallbackFormState)
  const formRef = useRef<HTMLFormElement>(null)

  const [doctor] = useAtom(requestCallbackModalDoctorSelectedAtom)

  const [contactMethod, _setContactMethod] = useState<
    | {
        value: string
        placeholder: string
      }
    | undefined
  >()
  const [errors, setErrors] = useState<{ message: string[] } | undefined>(
    undefined
  )
  const [errorsDate, setErrorsDate] = useState<
    { message: string[] } | undefined
  >(undefined)
  const [formData, _setFormData] = useState<RequestCallBackFromData>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    zipCode: '',
    emailAddress: '',
    contactMethod: '',
    date: '',
    time: '',
    subscribed: false,
    doctor,
  })
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formLoading, setFormLoading] = useState(false)

  const appContext = useContext(AppContext)

  const SvgCheckIcon = formData.subscribed
    ? SvgCheckTrueIcon
    : SvgCheckFalseIcon

  const setFormData = ({
    val,
    type,
  }: {
    val: any
    type: keyof RequestCallBackFromData
  }) =>
    _setFormData((prevState) => {
      return { ...prevState, [type]: val }
    })

  const handleAutopilot = () => {
    const isTestEmail = getIsTestEmail(formData.emailAddress)
    if (!formData.subscribed || !siteInfo.isUS || isTestEmail) {
      return
    }

    const body = {
      contact: {
        Email: formData.emailAddress,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        MailingPostalCode: formData.zipCode,
      },
    }

    fetch(
      process.env.NEXT_PUBLIC_DOCTORS_API_PROXY_URL +
        '/autopilot/captureUser.js',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    ).catch(() => {})
  }

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const selectedDate = new Date(formData.date)
    const selectedDay = selectedDate.getDay()
    if (selectedDay === 0 || selectedDay === 6) {
      setErrorsDate({ message: ['Weekend days are not allowed.'] })
      return
    }

    if (!formData.subscribed) {
      setFormSubmitted(true)
      return
    }

    setFormLoading(true)
    handleAutopilot()

    const res = await formie.send(formData)

    if (!res.success) {
      setErrors(res.errors)
    } else {
      setFormLoading(false)
      setErrors(undefined)
      TagManager.dataLayer({
        dataLayer: {
          event: 'request_a_callback',
          email: formData.emailAddress,
        },
      })
      _setFormState((prevState) => {
        return {
          ...prevState,
          step: 2,
          phoneNumber: formData.phoneNumber,
          emailAddress: formData.emailAddress,
          zipCode: formData.zipCode,
          contactMethod: formData.contactMethod,
          date: formData.date,
          time: formData.time,
        }
      })
      doctor && appContext?.updateDoctorRequestedCallback(doctor.slug)
      setTimeout(() => {
        appContext?.clearRecentlyRequestedDoctors()
      }, 5 * 60 * 1000)
    }
  }

  const handleTimeChange = (selectedOption: {
    value: string
    label: string
  }) => {
    if (selectedOption && 'value' in selectedOption) {
      setFormData({ val: selectedOption.value, type: 'time' })
    }
  }

  const datePickerRef = useRef<DatePicker | null>(null)

  const handleCalendarIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  return (
    <form ref={formRef} onSubmit={submitForm}>
      <h4 className="group-label mt-5 font-body">Personal details</h4>

      <div className="grid md:grid-cols-2 md:gap-5">
        <AFormInput
          placeholder={'First Name'}
          value={formData.firstName}
          required
          version="rounded"
          onChangeHandler={(val) => {
            setFormData({ val, type: 'firstName' })
          }}
        />
        <AFormInput
          placeholder={'Last Name'}
          value={formData.lastName}
          required
          version="rounded"
          onChangeHandler={(val) => {
            setFormData({ val, type: 'lastName' })
          }}
        />
      </div>
      <div className="grid md:grid-cols-2 md:gap-5">
        <AFormInput
          placeholder={'Phone Number'}
          value={formData.phoneNumber}
          required
          version="rounded"
          onChangeHandler={(val) => {
            setFormData({ val, type: 'phoneNumber' })
          }}
        />
        <AFormInput
          placeholder={'Zip Code'}
          value={formData.zipCode}
          required
          version="rounded"
          onChangeHandler={(val) => {
            setFormData({ val, type: 'zipCode' })
          }}
        />
      </div>
      <div className="grid md:grid-cols-1 md:gap-5">
        <AFormInput
          placeholder={'Email Address'}
          value={formData.emailAddress}
          required
          version="rounded"
          onChangeHandler={(val) => {
            setFormData({ val, type: 'emailAddress' })
          }}
        />
      </div>

      <h4 className="group-label mt-10">
        What is your preferred method of contact from your EVO Advisor?
      </h4>

      <div className="grid md:grid-cols-3 mt-5">
        {CONTACT_METHOD_OPTIONS.map((option) => {
          return (
            <ASortByRadio
              name="contactMethod"
              key={option.value}
              value={option.value}
              label={option.label}
              isChecked={contactMethod?.value === option.value}
              styles={sortRadioStyles}
              onChange={(val) => {
                setFormData({ val, type: 'contactMethod' })
                _setContactMethod({
                  value: val,
                  placeholder: option.placeholder,
                })
              }}
            />
          )
        })}
      </div>
      <>
        {errors?.message?.map((message: string) => (
          <div className="text-base font-semibold leading-7 text-gray-800 text-secondary-red mt-2">
            {message}
          </div>
        ))}
        <h4 className="group-label mt-10">
          When would you like to be contacted?
        </h4>

        <div className={'subscibed-message mt-5'}>
          Advisors are available from 7:00 a.m. to 5:00 p.m. PST. Please note
          that we will make every effort to contact you at your selected time
          within those hours, but the exact time cannot be guaranteed.
        </div>
        <div className="flex flex-col md:flex-row items-center mt-4 gap-4">
          <div style={{ flex: 1, width: '100%', position: 'relative' }}>
            <DatePicker
              ref={datePickerRef}
              wrapperClassName="w-full"
              className="w-full px-3 py-2 border rounded-full z-10 border-gray-800 h-[48px]"
              // @ts-ignore
              selected={formData.date}
              onChange={(val) => setFormData({ val, type: 'date' })}
              placeholderText="mm/dd/yyyy"
              dateFormat="MM/dd/yyyy"
              filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
              minDate={new Date()}
              required
            />
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="absolute top-1/2 right-4 text-black transform -translate-y-1/2 cursor-pointer h-3 w-3"
              onClick={handleCalendarIconClick}
            />
          </div>
          <div style={{ flex: 1, width: '100%' }}>
            <div className="relative">
              <Select
                value={
                  formData.time
                    ? { value: formData.time, label: formData.time }
                    : null
                }
                // @ts-ignore
                onChange={handleTimeChange}
                options={timeOptions}
                isSearchable={false}
                placeholder="--:-- --"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '9999px',
                    borderColor: 'rgb(108 101 104 / 1)',
                    borderWidth: '1px',
                    padding: '8px',
                    height: '48px',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? '#10a8ba'
                      : provided.backgroundColor,
                    color: state.isSelected ? '#fff' : provided.color,
                  }),
                }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                required
              />
              <FontAwesomeIcon
                icon={faClock}
                className="absolute top-1/2 right-4 text-black transform -translate-y-1/2 cursor-pointer h-3 w-3"
              />
            </div>
          </div>
        </div>

        <div
          className="cursor-pointer flex mt-7"
          onClick={() =>
            setFormData({
              val: !formData.subscribed,
              type: 'subscribed',
            })
          }
        >
          <SvgCheckIcon className="flex-shrink-0 fill-current mr-4 text-gray-800 w-3 h-3" />
          <p className="subscibed-message">
            By checking this box, you consent to communications (i) regarding
            STAAR products; and (ii) with information on local EVO ICL®
            providers. A STAAR Advisor partner will contact you by email, phone
            or automated SMS text at the number provided for the purposes of
            finding an EVO ICL provider and scheduling an appointment. If you
            wish to opt out of further marketing communications, you can
            unsubscribe via the link at the bottom of the relevant marketing
            email or contact
            <a href="mailto:PrivacyOffice@staar.com">
              {' '}
              PrivacyOffice@staar.com{' '}
            </a>
            at any time. For further information on STAAR’s privacy practices,
            please see our online{' '}
            <a href="https://us.discovericl.com/privacy-policy">
              Privacy Policy
            </a>
            .
          </p>
        </div>
        {!formData.subscribed && formSubmitted && (
          <div className="subscribeError">
            <p>Checkbox field is required</p>
          </div>
        )}
        <div className="mt-5 text-center  md:w-full  md:px-24">
          <AButton
            size="lg"
            className={'w-full'}
            type="submit"
            disabled={
              (doctor &&
                appContext?.state?.recentlyRequestedDoctors.includes(
                  doctor.slug
                )) ||
              formLoading
            }
          >
            {formLoading ? (
              <ASvg className="w-6 h-6" svg={SvgLoadingIcon} />
            ) : (
              <>Submit my information</>
            )}
          </AButton>
        </div>
      </>
      <style jsx>{styles}</style>
    </form>
  )
}

const styles = css`
  .group-label {
    font-weight: 600;
    size: 22px;
    line-height: 22px;
  }

  .subscibed-message {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .subscibed-message > a,
  .subscibed-message > span > a {
    text-decoration: underline;
    color: #30b2c0;
  }

  .subscribeError {
    font-size: 12px;
    color: red;
    margin-left: 25px;
    margin-top: 5px;
  }
`

const sortRadioStyles = css`
  .a-sort-by-radio:hover {
    text-decoration-line: underline;
    text-decoration-color: #10a8ba;
    text-decoration-thickness: 4px;
    text-underline-offset: 4px;
    color: #57606c;
  }

  .a-sort-by-radio--selected {
    accent-color: #30b2c0;
  }
`

const CONTACT_METHOD_OPTIONS = [
  {
    label: 'Phone',
    value: 'phone',
    placeholder: 'Enter your Phone Number',
  },
  {
    label: 'Text',
    value: 'text',
    placeholder: 'Enter your Mobile Number',
  },
  {
    label: 'Email',
    value: 'email',
    placeholder: 'Enter your Email',
  },
]
