import { AButton, AHyperlink } from 'ui'
import { useTranslation } from 'next-i18next'
import { useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import css from 'styled-jsx/css'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { Location } from '../../context/reducers/locations'
import useSettings from '../../hooks/use-settings'
import { MListItem } from '../molecules/m-list-litem'
import { AppContext } from '../../context/app'
import { Doctor } from '../../context/reducers/doctors'

type Props = {
  location: Location
  onBack?: () => void
}

export const CLocationDetail = ({ location, onBack }: Props) => {
  const settings = useSettings()
  const siteInfo = useSiteInfo()
  const router = useRouter()
  const { t } = useTranslation('common')

  const appContext = useContext(AppContext)

  const computedDoctors = useMemo(() => {
    if (!location) {
      return []
    } else {
      return appContext?.state?.doctors?.filter((doctor: Doctor) => {
        return location.doctors.includes(doctor.id)
      })
    }
  }, [location])

  const handleCloseButtonClick = () => {
    if (onBack instanceof Function) {
      onBack()
    }
  }

  const handleDoctorClick = (id: number) => {
    const doctor = appContext?.state?.doctors?.find((v) => v.id === id)
    if (doctor) {
      router.push(`/doctors/${doctor.slug}/about`, undefined, { shallow: true })
    }
  }

  return (
    <div className="flex flex-col">
      <div className="px-4">
        <AButton
          type="button"
          className="mt-4 w-full"
          size="lg"
          style="outline"
          color="black"
          onClick={handleCloseButtonClick}
        >
          <span>{t('back-to-results')}</span>
        </AButton>
      </div>
      {location && (
        <div>
          <div className="flex p-4">
            <div className="flex-grow mr-4">
              <div className="text-gray-900 text-2xl text-semibold leading-8">
                {location.title}
              </div>
              <div
                className="text-gray-800 mt-2 leading-7"
                dangerouslySetInnerHTML={{ __html: location.address }}
              />
            </div>
            {settings.search === 'geocoding' && (
              <div className="flex-shrink-0 flex flex-col text-gray-800 items-end">
                <div>
                  {location.distance} {settings.unit.label}
                </div>
              </div>
            )}
          </div>
          <div className="flex px-4 pb-6">
            <AHyperlink
              type="link"
              href={`/locations/${location.slug}/about`}
              color={
                siteInfo?.layout === CONST.SITE_LAYOUT.ICL ? 'teal' : 'pink'
              }
              className="mr-4"
            >
              <span>{t('view-details')}</span>
            </AHyperlink>
            <AHyperlink
              type="a"
              href={'https://maps.google.com?q=' + location.address}
              color={
                siteInfo?.layout === CONST.SITE_LAYOUT.ICL ? 'teal' : 'pink'
              }
              target="_blank"
            >
              <span>{t('get-directions')}</span>
            </AHyperlink>
          </div>
          <div className="c-location-detail__doctor-count relative flex justify-center">
            <div className="bg-white border border-gray-200 font-body font-base font-semibold text-gray-900 px-4 py-2 rounded-full">
              {location.doctors.length}{' '}
              {location.doctors.length > 1 ? t('doctors') : t('doctor')}
            </div>
          </div>
          <div>
            {computedDoctors?.map((doctor: Doctor) => {
              return (
                <MListItem
                  key={doctor.id}
                  objectId={doctor.id}
                  type="doctor"
                  name={[doctor.title, doctor.subtitle]}
                  address={doctor.address}
                  avatar={doctor.avatar}
                  specialist={doctor.specialist}
                  onActive={handleDoctorClick}
                />
              )
            })}
          </div>
        </div>
      )}
      <style jsx>{styles}</style>
    </div>
  )
}

const styles = css`
  .c-location-detail__doctor-count::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #cfcfcf;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;
  }
`
