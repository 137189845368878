import formie from '../helpers/formie'
import { useSiteInfo } from 'shared'

export const useFormie = () => {
  const siteInfo = useSiteInfo()

  const formieInstance = formie(siteInfo)

  return formieInstance
}

export default useFormie
