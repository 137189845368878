import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { CraftLocationDoctor } from '../../craft-types'
import { AInlineVideo } from '../atoms/a-inline-video'
import { ATab } from '../atoms/a-tab'
import { MModal, MModalRef } from '../molecules/m-modal'
import { AppContext } from '../../context/app'
import { MShowMore } from '../molecules/m-show-more'
import avatar from '../../helpers/avatar'
import { CDetails } from './c-details'
import { CGallery } from './c-gallery'
import { CInformation } from './c-information'
import { CMap } from './c-map'
import { CContactForm } from './c-contact-form'
import { CDetailsDoctors } from './c-details-doctors'

export const CLocationModal = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  const modalRef = useRef<MModalRef>(null)

  const [contactFormStep, setContactFormStep] = useState<number>(1)

  const appContext = useContext(AppContext)

  const urlData = useMemo(() => {
    const matches = router.asPath.match(
      /^\/(?<type>doctors|locations)\/(?<slug>[^/]+)\/(?<tab>[^?^/]+)/
    )

    if (
      matches?.groups?.type &&
      matches?.groups?.slug &&
      matches?.groups?.tab
    ) {
      return {
        type: matches.groups.type,
        slug: decodeURI(matches.groups.slug),
        tab: matches.groups.tab,
      }
    }

    return {
      type: null,
      slug: null,
      tab: null,
    }
  }, [router.asPath])

  const doctors = useMemo(() => {
    return appContext?.state?.doctors
      ?.map((doctor) => {
        return appContext?.state?.location?.locationDoctors?.find(
          (v) => v.slug === doctor.slug
        )
      })
      .filter((v) => v)
  }, [appContext?.state?.location]) as Array<CraftLocationDoctor>

  const computedHeading = useMemo(() => {
    if (urlData.tab === CONST.URL_DATA_TAB.CONTACT) {
      if (contactFormStep === 1) {
        return `${t(CONST.URL_DATA_TAB.CONTACT)} ${
          appContext?.state?.location?.title
        }`
      } else {
        return t('message-submitted')
      }
    } else {
      return appContext?.state?.location?.title
    }
  }, [appContext?.state?.location])

  const officeHours = useMemo(() => {
    return (
      appContext?.state?.location?.locationHours?.map((v) =>
        (v.text || '').trim()
      ) ?? []
    )
  }, [appContext?.state?.location])

  const photo = useMemo(() => {
    let image = ''
    if (appContext?.state?.location?.locationPicture?.length) {
      image = (appContext?.state?.location?.locationPicture[0] || {}).url
    }
    return avatar('doctor', image, siteInfo?.layout ?? 'icl')
  }, [appContext?.state?.location])

  const languages = useMemo(() => {
    return (appContext?.state?.location?.locationLanguages || []).map(
      (v) => v.text
    )
  }, [appContext?.state?.location])

  const clinic = useMemo(() => {
    return (appContext?.state?.location?.locationClinicSpecific || []).map(
      (v) => v.text
    )
  }, [appContext?.state?.location])

  const payments = useMemo(() => {
    return (appContext?.state?.location?.locationPayments || []).map(
      (v) => v.text
    )
  }, [appContext?.state?.location])

  const handleScrollToTop = () => {
    if (modalRef.current) {
      modalRef.current.scrollToTop()
    }
  }

  const handleScrollToTabs = () => {
    if (modalRef.current) {
      modalRef.current.scrollToTabs()
    }
  }

  useEffect(() => {
    if (
      ![
        CONST.URL_DATA_TAB.ABOUT,
        CONST.URL_DATA_TAB.INFORMATION,
        CONST.URL_DATA_TAB.DOCTORS,
        CONST.URL_DATA_TAB.CONTACT,
      ].includes(urlData.tab ?? '')
    ) {
      router.replace(
        `/${urlData.type}/${urlData.slug}/${CONST.URL_DATA_TAB.ABOUT}`,
        undefined,
        { shallow: true }
      )
    }

    ;(async () => {
      if (urlData.slug && appContext?.state?.location?.slug !== urlData.slug) {
        appContext?.updateLocation(decodeURI(urlData.slug))
      }
    })()
  }, [])

  return (
    <MModal
      ref={modalRef}
      mobileTitle={t(urlData.tab ?? '')}
      top={
        <>
          {appContext?.state?.location?.slug === urlData.slug && (
            <CMap
              markers={[
                {
                  lat: appContext.state.location.locationLatitude ?? 0,
                  lng: appContext.state.location.locationLongitude ?? 0,
                  selected: true,
                  clickable: false,
                },
              ]}
            />
          )}
        </>
      }
      content={
        <>
          {appContext?.state?.location?.slug === urlData.slug && (
            <CDetails
              name={computedHeading ?? ''}
              photo={photo}
              location={appContext.state.location}
              hours={officeHours}
              onScrollToTop={handleScrollToTop}
              onScrollToTabs={handleScrollToTabs}
            >
              <ATab
                active={urlData.tab === CONST.URL_DATA_TAB.ABOUT}
                handle={CONST.URL_DATA_TAB.ABOUT}
                title={t(CONST.URL_DATA_TAB.ABOUT) ?? ''}
              >
                {appContext.state.location.locationBiography && (
                  <>
                    <h2 className="font-body text-2xl text-gray-900 font-semibold">
                      {t(CONST.URL_DATA_TAB.ABOUT)}{' '}
                      {appContext.state.location.title}
                    </h2>
                    <MShowMore
                      className="mt-4"
                      content={appContext.state.location.locationBiography}
                    />
                  </>
                )}
                {!!appContext.state.location.locationVideo?.length && (
                  <AInlineVideo
                    code={
                      appContext.state.location.locationVideo[0].embeddedAsset
                        .code
                    }
                  />
                )}
                {!!appContext.state.location.locationGallery?.length && (
                  <>
                    <h2 className="font-body text-2xl text-gray-900 font-semibold mt-10">
                      {t('photos-of')} {appContext.state.location.title}
                    </h2>
                    <CGallery
                      className="mt-4"
                      images={appContext.state.location.locationGallery}
                    />
                  </>
                )}
              </ATab>
              <ATab
                active={urlData.tab === CONST.URL_DATA_TAB.INFORMATION}
                handle={CONST.URL_DATA_TAB.INFORMATION}
                title={t(CONST.URL_DATA_TAB.INFORMATION) ?? ''}
              >
                <CInformation
                  name={appContext.state.location.title ?? ''}
                  rows={[
                    [
                      {
                        label: t('clinic-specific'),
                        type: 'list',
                        items: clinic,
                      },
                    ],
                    [
                      {
                        label: t('languges-spoken'),
                        type: 'list',
                        items: languages,
                      },
                      {
                        label: t('accepted-payments'),
                        type: 'list',
                        items: payments,
                      },
                    ],
                  ]}
                  social={appContext.state.location.locationSocial ?? []}
                />
              </ATab>
              {doctors.length > 0 && (
                <ATab
                  active={urlData.tab === CONST.URL_DATA_TAB.DOCTORS}
                  handle={CONST.URL_DATA_TAB.DOCTORS}
                  title={t(CONST.URL_DATA_TAB.DOCTORS) ?? ''}
                >
                  <CDetailsDoctors doctors={doctors ?? []} />
                </ATab>
              )}
              <ATab
                active={urlData.tab === CONST.URL_DATA_TAB.CONTACT}
                handle={CONST.URL_DATA_TAB.CONTACT}
              >
                <CContactForm
                  location={appContext.state.location}
                  name={appContext.state.location.title}
                  step={contactFormStep}
                  setStep={setContactFormStep}
                />
              </ATab>
            </CDetails>
          )}
        </>
      }
    />
  )
}
