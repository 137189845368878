import generateUuidv4 from 'doctors/helpers/generate-uuid'
import { Doctor } from '../context/reducers/doctors'
import { SiteInfo } from 'shared'

export type RequestCallBackFromData = {
  firstName: string
  lastName: string
  phoneNumber: string
  emailAddress: string
  zipCode: string
  contactMethod: string
  date: string
  time: string
  doctor?: Doctor
  subscribed: boolean
}
const formieRequestCallback = (siteInfo: SiteInfo | null) => {
  const url = `/proxy/${siteInfo?.layout ?? ''}`

  const uuid = () => {
    let _uuid = localStorage.getItem('USER_UUID')

    if (!_uuid) {
      _uuid = generateUuidv4()

      localStorage.setItem('USER_UUID', _uuid)
    }

    return _uuid
  }

  const parse = (data: RequestCallBackFromData, appendUserUuid = true) => {
    const formData = new FormData()

    formData.append('handle', 'requestCallback')

    if (data.doctor) {
      formData.append('fields[doctorID]', data.doctor?.id?.toString() ?? '')
      formData.append('fields[doctorName]', data?.doctor?.title ?? '')
      formData.append('fields[doctorAddress]', data?.doctor?.address ?? '')
    }

    formData.append('fields[firstName]', data.firstName)
    formData.append('fields[lastName]', data.lastName)
    formData.append('fields[phoneNumber]', data.phoneNumber)
    formData.append('fields[emailAddress]', data.emailAddress)
    formData.append('fields[zipCode]', data.zipCode)
    formData.append('fields[contactMethod]', data.contactMethod)
    formData.append('fields[date]', data.date)

    // if we not specify an email formie will use default email from settings in the FORM
    // formData.append('fields[email]', 'advisorteam@evo-icl.com')
    formData.append('fields[time]', data?.time ?? '')
    formData.append(
      'fields[subscribed]',
      data?.subscribed?.toString() ?? 'false'
    )

    if (appendUserUuid) {
      formData.append('fields[userUuid]', uuid())
    }

    return formData
  }

  const send = async (data: RequestCallBackFromData, appendUserUuid = true) => {
    try {
      const getForm = await fetch(url + '/info', {
        cache: 'no-cache',
        headers: { accept: 'application/json' },
      })
      const { csrfTokenValue } = await getForm.json()

      const formData = parse(data, appendUserUuid)
      formData.append('CRAFT_CSRF_TOKEN', csrfTokenValue || '')

      const postForm = await fetch(url + '/submit', {
        method: 'post',
        cache: 'no-cache',
        headers: { accept: 'application/json' },
        body: formData,
      })
      const { errors, success } = await postForm.json()

      return { errors, success }
    } catch (e) {
      console.error(e)

      return {
        errors: { message: ['message-unexpected-error'] },
        success: false,
      }
    }
  }

  return { parse, send }
}

export default formieRequestCallback
