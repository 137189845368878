import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useMemo, useState, useContext } from 'react'
import { ALightSwitch, ALightSwitchOption, CStats } from 'ui'
import TagManager from 'react-gtm-module'
import Image from "next/image"
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import { AppContext } from '../../context/app'
import { CraftRegion } from '../../craft-types'
import useSettings from '../../hooks/use-settings'
import { MDropdown, MDropdownOption } from '../molecules/m-dropdown'
import { MSearchInput } from '../molecules/m-search-input'

export const CLandingSearch = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const settings = useSettings()
  const siteInfo = useSiteInfo()

  const [type, setType] = useState<string>(settings.types.default)

  const appContext = useContext(AppContext)

  const regionsOptions: Array<MDropdownOption> = useMemo(() => {
    return (appContext?.state?.regions ?? []).map((region: CraftRegion) => {
      return {
        option: region.title ?? '',
        value: region.slug ?? '',
        selected: false,
      }
    })
  }, [appContext?.state?.regions])

  const handleSubmit = async (value: string) => {
    if (type === 'doctors') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'doctorSearch',
          event_category: 'Doctor Search',
          event_action: 'Landing Search component on finder page',
          event_label: value,
        },
      })
    } else if (type === 'locations') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'locationSearch',
          event_category: 'Location Search',
          event_action: 'Landing Search component on finder page',
          event_label: value,
        },
      })
    }

    const query = {
      q: value,
      type,
    }
    const queryParams = new URLSearchParams(query).toString()
    router.push(`/?${queryParams}`, undefined, { shallow: true })
  }

  const handleRegionChange = (option: MDropdownOption) => {
    if (type === 'doctors') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'doctorSearch',
          event_category: 'Doctor Search',
          event_action: 'Landing Search component on finder page',
          event_label: option.value,
        },
      })
    } else if (type === 'locations') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'locationSearch',
          event_category: 'Location Search',
          event_action: 'Landing Search component on finder page',
          event_label: option.value,
        },
      })
    }

    const query = {
      q: option.value,
      type,
    }
    const queryParams = new URLSearchParams(query).toString()
    router.push(`/?${queryParams}`, undefined, { shallow: true })
  }

  const handleTypeChange = (option: ALightSwitchOption) => {
    setType(option.value)
  }

  // useEffect(() => {
  //   if (queryContext.state.lat && queryContext.state.lng) {
  //     (async () => {
  //       const location = await mapbox.reverseGeocode(queryContext.state.lat ?? 0, queryContext.state.lng ?? 0)
  //       if (location.features.length > 0) {
  //         const query = { q: location.features[0].place_name }
  //         queryContext.dispatch({
  //           type: 'update',
  //           payload: query
  //         })
  //         const queryParams = new URLSearchParams(query).toString()
  //         router.replace(`/?${queryParams}`)
  //       }
  //     })()
  //   }
  // }, [])

  /* eslint-disable @next/next/no-img-element */
  return (
    <div
      className={classNames(
        'w-full h-full flex items-center justify-center relative',
        {
          'bg-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
          'bg-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
        }
      )}
    >
      <img
        className="absolute top-0 left-0 pointer-events-none"
        src="/img/lp-bg-1.png"
        alt="Background shape"
      />
      <img
        className="absolute bottom-0 right-0 pointer-events-none"
        src="/img/lp-bg-2.png"
        alt="Background shape"
      />
      <div className="min-h-[840px] max-w-[853px] absolute w-full m-4  top-40">
        <img
          className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 z-10 pointer-events-none hidden lg:block"
          src="/img/lp-bg-3.png"
          alt="Background shape"
        />
        <img
          className="absolute bottom-0 left-0 transform -translate-x-1/2 translate-y-1/2 z-10 pointer-events-none"
          src="/img/lp-bg-4.png"
          alt="Background shape"
        />
        <div className="bg-neutral-white px-4 py-12 rounded-md w-full flex flex-col items-center font-body relative z-20">
          <h1 className="text-4xl font-light text-gray-900">
            {t('find-a-doctor')}
          </h1>
          <h2 className="max-w-[568px] mt-2 text-2xl text-gray-800 w-full text-center font-light">
            {t('find-a-doctor-instructions')}
          </h2>
          <ALightSwitch
            className="mt-4"
            options={settings.types.options.map((option) => {
              return {
                label: t(option.label),
                value: option.value,
              }
            })}
            onChange={handleTypeChange}
          />
          <div className="max-w-[611px] w-full mt-6">
            {settings.search !== 'regions' && (
              <MSearchInput onSubmit={handleSubmit} />
            )}
            {settings.search === 'regions' && (
              <MDropdown
                label={t('region')}
                full={true}
                placeholder={t('select-a-region') ?? ''}
                options={regionsOptions}
                onChange={handleRegionChange}
              />
            )}
          </div>
        </div>
        {siteInfo.isJP && (
          <div className='flex justify-center text-center my-7 max-w-[80vw] mx-auto'>
            <a href='https://staaricl.jp/' target='_blank' rel="noreferrer" className='transition-opacity hover:opacity-80'>
              <Image className='rounded-3xl' src='/img/jp-badge.png' alt='ICL Certified badge' width={300} height={300} />
              <span className='text-white underline text-xl'>staaricl.jp</span>
            </a>
          </div>
        )}
        {siteInfo?.iso === 'en-US' && (
          <>
            <h1 className="mt-8 text-center text-4xl font-light text-white">
              {t('titles.about-visian')}
            </h1>
            <CStats
              small={true}
              field={[
                {
                  typeHandle: 'stat',
                  copy: t('titles.about-visian1') ?? '',
                },
                {
                  typeHandle: 'stat',
                  copy: t('titles.about-visian2') ?? '',
                },
                {
                  typeHandle: 'stat',
                  copy: t('titles.about-visian3') ?? '',
                },
              ]}
            />
          </>
        )}
      </div>
    </div>
  )
}
