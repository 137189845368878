import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import css from 'styled-jsx/css'
import { Trans, useTranslation } from 'next-i18next'
import { useSiteInfo } from 'shared'
import { AppContext, AppContextType } from '../../context/app'
import { Location } from '../../context/reducers/locations'
import { MHeading } from '../molecules/m-heading'
import useSettings from '../../hooks/use-settings'
import { CList, CListRef } from './c-list'
import { CMap, CMapRef } from './c-map'
import { CSearchBar } from './c-search-bar'
import { CSortFilter } from './c-sort-filter'

interface CSearchProps {
  isLanding?: boolean
}
export const CSearch = ({ isLanding = false }: CSearchProps) => {
  const siteInfo = useSiteInfo()
  const settings = useSettings()
  const { t } = useTranslation('common')

  const [isMapOpen, setIsMapOpen] = useState(false)
  const [showLocationDetail, setShowLocationDetail] = useState<boolean>(false)
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  )

  const listRef = useRef<CListRef>(null)
  const mapRef = useRef<CMapRef>(null)

  const appContext = useContext(AppContext)
  const appContextRef = useRef<AppContextType | null>(null)
  appContextRef.current = appContext

  const normalizedMarkers = useMemo(() => {
    return appContext?.state?.locations?.map((location) => ({
      lng: location._geoloc.lng,
      lat: location._geoloc.lat,
      count:
        appContext.state.query?.type === 'doctors' &&
        location.doctors.length > 1
          ? location.doctors.length
          : null,
      id: location.id,
    }))
  }, [appContext?.state?.locations])

  const pushSegmentSearch = () => {
    if (!window.analytics || siteInfo?.iso !== 'en-US') {
      return
    }
    window.analytics.track('Search Doctors', {
      search_lat: appContext?.state?.query?.lat,
      search_lng: appContext?.state?.query?.lng,
      search_radius: appContext?.state?.query?.radius,
      doctor_count: appContext?.state?.doctors?.length,
    })
  }

  const handleListSelection = (id: number | null, type: string) => {
    if (id === null && mapRef.current) {
      mapRef.current.selectMultipleMarkers([])
      mapRef.current.fitMarkers()
    }

    if (type === 'doctors') {
      const locationIds: Array<number> = []
      appContext?.state?.locations?.forEach((location) => {
        if (location.doctors.includes(id ?? 0)) {
          locationIds.push(location.id)
        }
      })
      if (mapRef.current) {
        mapRef.current.selectMultipleMarkers(locationIds)
      }
    } else if (type === 'locations') {
      if (mapRef.current) {
        mapRef.current.selectMultipleMarkers([id ?? 0])
      }
    }
  }

  const handleCloseLocationDetail = () => {
    setShowLocationDetail(false)
    mapRef.current?.selectMultipleMarkers([])
    if (mapRef.current) {
      mapRef.current.fitMarkers()
    }
  }

  const handleMarkerClick = (type: string, id: number) => {
    if (appContextRef.current) {
      const location = appContextRef.current.state.locations.find(
        (v) => v.id === id
      )

      if (appContextRef.current.state.query.type === 'doctors') {
        setShowLocationDetail(true)
        setSelectedLocation(location ?? null)
      } else {
        listRef.current?.setActiveLocation(id)
      }
    }
  }

  useEffect(() => {
    pushSegmentSearch()
  }, [])

  if (siteInfo.isUS) {
    return (
      <div
        className={`c-search__wrapper ${
          !siteInfo?.iframe &&
          siteInfo?.layout &&
          `c-search__wrapper--${siteInfo?.layout}`
        }`}
      >
        <MHeading
          title={t('find-a-doctor')}
          subtitle={t('find-a-doctor-instructions')}
          rightContent={
            <div className="text-center">
              <h2 className="text-2xl">
                <Trans
                  i18nKey="heading-text"
                  components={{
                    span: <span className="block text-sm" />,
                  }}
                />
              </h2>
            </div>
          }
        >
          <CSearchBar />
        </MHeading>
        {settings.search === 'geocoding' && !showLocationDetail && (
          <div className="px-5 xl:px-10">
            <CSortFilter
              setIsMapOpen={setIsMapOpen}
              isMapOpen={isMapOpen}
              map={
                isLanding ? undefined : (
                  <div className="md:hidden w-full md:w-2/6 bg-gray-100 h-[220px]">
                    <CMap
                      ref={mapRef}
                      markers={normalizedMarkers ?? []}
                      onMarkerClick={handleMarkerClick}
                      navigation={true}
                      isVisible={isMapOpen}
                    />
                  </div>
                )
              }
            />
          </div>
        )}
        <div className="px-5 md:flex xl:px-10 min-h-[590px]">
          <div className="c-search__sidebar bg-neutral-white z-30 w-full md:w-4/6 md:mr-6">
            {isLanding ? (
              <h1 className="text-gray-700 text-2xl text-center my-20">
                {t('enter-zip-code')}
              </h1>
            ) : (
              <CList
                ref={listRef}
                loading={false}
                showLocationDetail={showLocationDetail}
                locationDetail={selectedLocation}
                onSelected={handleListSelection}
                onCloseLocationDetail={handleCloseLocationDetail}
              />
            )}
          </div>
          <div className="hidden md:block w-full md:w-2/6 bg-gray-100 max-h-[600px] sticky top-[120px]">
            <CMap
              ref={mapRef}
              markers={normalizedMarkers ?? []}
              onMarkerClick={handleMarkerClick}
              navigation={true}
            />
          </div>
        </div>
        <style jsx>{usStyles}</style>
      </div>
    )
  }

  return (
    <div
      className={`md:flex c-search__content ${
        !siteInfo?.iframe &&
        siteInfo?.layout &&
        `c-search__content--${siteInfo?.layout}`
      }`}
    >
      <div className="c-search__sidebar bg-neutral-white z-30">
        <CList
          ref={listRef}
          loading={false}
          showLocationDetail={showLocationDetail}
          locationDetail={selectedLocation}
          onSelected={handleListSelection}
          onCloseLocationDetail={handleCloseLocationDetail}
        />
      </div>
      <div className="hidden md:block md:w-full bg-gray-100">
        <CMap
          ref={mapRef}
          markers={normalizedMarkers ?? []}
          onMarkerClick={handleMarkerClick}
          navigation={true}
        />
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

const styles = css`
  .c-search__content--evo {
    margin-top: 64px;
  }

  .c-search__content--icl {
    margin-top: 80px;
  }

  @media only screen and (min-width: 768px) {
    .c-search__content {
      height: calc(100vh);
    }

    .c-search__content--evo {
      height: calc(100vh - 64px);
    }

    .c-search__content--icl {
      height: calc(100vh - 114px);
      margin-top: 114px;
    }

    .c-search__sidebar {
      box-shadow: 4px 1px 8px rgba(4, 29, 62, 0.0898711);
      flex: 0 0 auto;
      width: 464px;
    }
  }

  @media only screen and (min-width: 1280px) {
    .c-search__content--evo {
      height: calc(100vh - 128px);
      margin-top: 128px;
    }
  }
`

const usStyles = css`
  .c-search__wrapper--evo {
    margin-top: 64px;
  }

  .c-search__wrapper--icl {
    margin-top: 80px;
  }

  @media only screen and (min-width: 768px) {
    .c-search__wrapper--icl {
      margin-top: 114px;
    }

    .c-search__sidebar {
      flex: 0 0 auto;
    }
  }

  @media only screen and (min-width: 1280px) {
    .c-search__wrapper--evo {
      margin-top: 128px;
    }
  }
`
