import { useRouter } from 'next/router'
import TagManager from 'react-gtm-module'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { MDropdown, MDropdownOption } from '../molecules/m-dropdown'
import { MSearchInput } from '../molecules/m-search-input'
import useSettings from '../../hooks/use-settings'
import { AppContext, AppContextType } from '../../context/app'
import { CraftRegion } from '../../craft-types'

export const CSearchBar = () => {
  const settings = useSettings()
  const router = useRouter()

  const { t } = useTranslation('common')
  const appContext = useContext(AppContext) as AppContextType
  const isDoctorEvent =
    appContext.state.query.type === 'doctors' ||
    appContext.state.query.type === ''
  const isLocationEVent = appContext.state.query.type === 'locations'

  console.log({
    isDoctorEvent,
    isLocationEVent,
  })

  const doctorSearchEvent = (value: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'doctorSearch',
        event_category: 'Doctor Search',
        event_action: 'List component on search results page',
        event_label: value,
      },
    })
  }

  const locationSearchEvent = (value: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'locationSearch',
        event_category: 'Location Search',
        event_action: 'List component on search results page',
        event_label: value,
      },
    })
  }

  const handleRegionChange = (option: MDropdownOption) => {
    const query = {
      ...router.query,
      q: option.value,
    }

    const queryParams = new URLSearchParams(query).toString()
    router.push(`/?${queryParams}`, undefined, { shallow: true })

    if (isDoctorEvent) {
      doctorSearchEvent(option.value)
    }

    if (isLocationEVent) {
      locationSearchEvent(option.value)
    }
  }

  const handleSubmitSearch = (value: string) => {
    if (isDoctorEvent) {
      doctorSearchEvent(value)
    }

    if (isLocationEVent) {
      locationSearchEvent(value)
    }

    const query = {
      q: value,
      type: (router.query.type as string) ?? '',
    }

    const queryParams = new URLSearchParams(query).toString()
    router.push(`/?${queryParams}`, undefined, { shallow: true })
  }

  const regionsOptions: Array<MDropdownOption> = useMemo(() => {
    return appContext.state.regions.map((region: CraftRegion) => {
      return {
        option: region.title ?? '',
        value: region.slug ?? '',
        selected: appContext.state.query.q === region.slug,
      }
    })
  }, [appContext.state.query.q, appContext.state.regions])

  return (
    <div>
      {settings.search === 'regions' && (
        <MDropdown
          label={t('region')}
          full={true}
          placeholder={t('select-a-region') ?? ''}
          options={regionsOptions}
          onChange={handleRegionChange}
        />
      )}
      {settings.search !== 'regions' && (
        <MSearchInput onSubmit={handleSubmitSearch} />
      )}
    </div>
  )
}
